import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data7d: any;
  user: any;
  getUser: any;
}

const LastWeekPayment = (props: Props) => {
  const { data7d, user, getUser } = props;
  const { t } = useTranslation();
  const [viewPaymentData, setViewPaymentData] = React.useState<any>([]);
  const handleOpenPayment = (id: any) => {
    if (viewPaymentData.includes(id)) {
      const tempPayment = viewPaymentData.filter((item:any) => item !== id);
      setViewPaymentData(tempPayment);
    } else {
      setViewPaymentData([...viewPaymentData, id]);
    }
  };
  console.log(viewPaymentData);

  return (
    <>
      {data7d?.map((data: any) => (
        <tbody className='' key={data.id}>
          <tr className={`text-center hover:bg-gray-200 ${viewPaymentData.includes(data.id) ? 'border-2 border-red-400 hover:border-red-400' : 'bg-white'}`}>
            <td className='px-6 py-4'>{data.id}</td>
            <td className='px-6 py-4'>{data.user_id}</td>
            <td className='px-6 py-4'>{data.price}</td>
            <td className={`px-6 py-4 ${ data.status == 'PAID' ? 'text-green-600' : data.status == 'CANCELED' ? 'text-auctionicRed' : data.status == 'PENDING' ? 'text-yellow-600' : data.status == 'DRAFT' ? 'text-gray-600' : ''}`}>{data.status}</td>
            <td className='px-6 py-4'>
              <button
                className={`px-1 py-1 text-sm bg-darkGray text-white rounded-md`}
                onClick={() => {handleOpenPayment(data.id);}}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='white'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M14 5l7 7m0 0l-7 7m7-7H3'
                  />
                </svg>
              </button>
            </td>
          </tr>
          { viewPaymentData.includes(data.id) && (
            <tr>
              <td colSpan={5} className='col-span-3'>
                <div className='px-2 sm:px-12 my-[20px] '>
                  <div className='w-full bg-darkGray rounded-md text-white p-5'>
                    <div className=''>
                      <button
                        className={'bg-auctionicRed px-8 py-2 rounded-md text-white'}
                        onClick={() => {getUser(data.user_id);}}
                      >
                        {t('payments.getUserDetails')}
                      </button>
                    </div>
                    { user?.id == data?.user_id && (
                      <div className='bg-gray-500 my-4 p-3 rounded-md'>
                        <p>
                          {t('payments.name')}: {user?.name != null ? user?.name
                            :
                            user?.first_name + ' ' + user?.last_name
                          }
                        </p>
                        <p>{t('payments.address')}: {user.address.formatted}</p>
                        <p>{t('payments.email')}: {user.email}</p>
                        <p>{t('payments.phoneNumber')}: {user.phone}</p>
                        <p>{t('payments.telephone')}: {user.telephone}</p>
                      </div>
                    )}
                    <div className='flex justify-between grid-cols-2'>
                      <div className='py-3'>
                        <p>{t('payments.paymentId')}: {data.id}</p>
                        <p>{t('payments.createdAt')}: {data.created_at}</p>
                        <p>{t('payments.invoicePaidDate')}: {data.invoice_paid_date}</p>
                        <p>{t('payments.discount')}: {data.discount}</p>
                      </div>
                      { data?.plan_id != null &&
                        <div className='grid grid-flow-row bg-auctionicRed pr-10 p-3 rounded-md'>
                          <p>{t('payments.planId')}: {data.plan_id}</p>
                          <p>{t('payments.type')}: {data.plan.type}</p>
                          <p>{t('payments.tier')}: {data.plan.tier}</p>
                          <p>{t('payments.price')}: {data.plan.price}</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      ))}
    </>
  );
};

export { LastWeekPayment };
