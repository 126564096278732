import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const LastWeekUsers = ({ demographics }:{demographics: any}) => {
  const today = new Date();
  const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  const startDateLastWeek = lastWeek.toISOString().slice(0, 10);
  const startDateLastWeekWithoutHyphens = startDateLastWeek.replace(/-/g, '');
  const filteredDataLastWeek = demographics.report?.filter((item: any) => {
    const itemDate = item['ga:date'].replace(/-/g, '');
    return itemDate >= startDateLastWeekWithoutHyphens;
  });

  const lastWeekFilteredData = filteredDataLastWeek?.map((item: any) => ({
    name: item['ga:city'],
    value: parseInt(item['ga:users']),
    date: item['ga:date']
  }));

  const aggregatedData = lastWeekFilteredData?.reduce((accumulator: any, current: any) => {
    const { name, value } = current;
    const existingItem = accumulator.find((item: any) => item.name === name);
    if (existingItem) {
      existingItem.value += value;
    } else {
      accumulator.push({ name, value });
    }
    return accumulator;
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1919', '#796cec', '#4ae1d7', '#f35832', '#de495b', '#66dc13', '#3fdece', '#e2b768', '#5503bc', '#8f1d60',
    '#d59464',
  ];

  return (
    <div style={{ width: '100%', height: '800px', display: 'flex', justifyContent: 'center' }}>
      <PieChart width={800} height={600}>
        <Pie
          data={aggregatedData}
          cx={'50%'}
          dataKey={'value'}
          cy={'50%'}
          innerRadius={80}
          width={1000}
          outerRadius={200}
          paddingAngle={2}
          fill="#8884d8"
          label={({ name, value }) => `${name}: ${value}`}
        >
          {
            aggregatedData?.map((entry: any, index:any) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))
          }
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export { LastWeekUsers, };
