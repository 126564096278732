import React from 'react';
import { useTranslation } from 'react-i18next';

const RevenueContent = () => {
  const { t } = useTranslation();
  return (
    <div className='pt-[62px]'>
      <div className='grid grid-cols-3'>
        <div className='block'>
          <div className='text-center'>
            <p className='text-[16px]'>{t('admin.totalRevenue24h')}</p>
            <p className='text-[22px] pt-[14px]'>CHF 1251.-</p>
            <p className='text-[12px] text-gray-500 pt-[23px]'>
              {t('admin.previous24h')}
            </p>
            <p className='text-[16px] text-red-600 font-semibold'>CHF -9.-</p>
          </div>
        </div>
        <div className='block '>
          <div className='text-center'>
            <p className='text-[16px]'>{t('admin.totalRevenue7d')}</p>
            <p className='text-[22px] pt-[14px]'>CHF 1251.-</p>
            <p className='text-[12px] text-gray-500 pt-[23px]'>
              {t('admin.previous7d')}
            </p>
            <p className='text-[16px] text-green-600 font-semibold'>
              CHF +62.-
            </p>
          </div>
        </div>
        <div className='block'>
          <div className='text-center'>
            <p className='text-[16px]'>{t('admin.totalRevenue30d')}</p>
            <p className='text-[22px] pt-[14px]'>CHF 1251.-</p>
            <p className='text-[12px] text-gray-500 pt-[23px]'>
              {t('admin.previous30d')}
            </p>
            <p className='text-[16px] text-green-600 font-semibold'>
              CHF +352.-
            </p>
          </div>
        </div>
      </div>
      <hr className='border-[#ABABAC] mt-[35px] mx-10' />
    </div>
  );
};
export { RevenueContent };
