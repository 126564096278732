import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchLogo from '../../images/filter.svg';
import { Sidenav } from '../Sidenav/Sidenav';
import { PostConfirm } from '../../config';
import { adminServices } from '../../services';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../images/logoRed.png';
import { SpinnerLoader } from '../Common';

const styles = StyleSheet.create({
  title: {
    margin: 20,
    fontSize: 25,
    textAlign: 'center',
    backgroundColor: '#e4e4e4',
    textTransform: 'uppercase',
    fontFamily: 'Oswald',
  },
  body: {
    flexGrow: 1,
    height: '100%',
    margin: '10%'
  },
  rowImage: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 40,
  },
  row: {
    flexGrow: 1,
    marginTop: '-45%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  rowBanner: {
    flexGrow: 1,
    marginTop: '-65%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rowAddress: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '-40%',
    paddingLeft: 20,
  },
  rowContact: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '-38%',
    paddingLeft: 20,
  },
  rowCode: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '-20%',
    paddingLeft: 20,
  },
  block: {
    flexGrow: 1,
  },
  textHeader: {
    width: '90%',
    marginTop: 5,
    textAlign: 'justify',
    fontSize: 16,
  },
  text: {
    width: '60%',
    marginLeft: 10,
    fontFamily: 'Oswald',
    fontSize: 12,
    textAlign: 'justify',
    fontWeight: 'normal',
  },
  textCode: {
    width: '100%',
    fontFamily: 'Oswald',
    fontSize: 32,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  textBanner: {
    width: '100%',
    fontFamily: 'Oswald',
    fontSize: 11,
    textAlign: 'center',
    fontWeight: 'light',
  },
  textContact: {
    width: '100%',
    fontFamily: 'Oswald',
    fontSize: 11,
    textAlign: 'center',
    fontWeight: 'light',
  },
  fill1: {
    width: '30%',
    marginTop: 50,
  },
  fill2: {
    flexGrow: 2,
    backgroundColor: 'darkgrey',
    height: '2px',
    width: '100%',
    marginRight: 20,
    marginTop: '-10%',
  },
  divider: {
    backgroundColor: 'red',
    height: 1,
    width: '100%',
  }
});
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const PostConfirmation = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [postConfirm, setPostConfirm] = useState<Array<PostConfirm>>([]);
  const [showData, setShowData] = useState(false);

  const [keyword, setKeyword,] = useState<string>('');
  const [filteredPost, setFilteredPost,] = useState(postConfirm);

  const filterPost = (postConfirm: Array<PostConfirm>) => {
    return postConfirm.filter((post) => post.user.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  useEffect(() => {
    setLoading(true);
    adminServices
      .getPostConfirms()
      .then((response: any) => {
        console.log(response.data.data);
        setPostConfirm(response.data.data);
        setFilteredPost(response.data.data);
        return response.data;
      })
      .catch((error: any) => {
        console.log('Error getting the post confirms', error);
      }).finally (() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if ( keyword !== '') {
      setFilteredPost(filterPost(postConfirm));
    }
    else { setFilteredPost(postConfirm); }
  }, [keyword,]);



  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav/>
        </div>
        <div className='col-span-3 pt-[68px]'>
          { loading ? ( <SpinnerLoader /> ) : (
            <div>
              <div className=''>
                <div className='grid grid-cols-5 px-2 sm:px-1'>
                  <div className='col-span-2 sm:col-span-3'>
                    <div className='flex '>
                      <input
                        type='search'
                        className='border border-[#ABABAC] rounded-md px-3 w-full h-[48px] '
                        placeholder={`${t('admin.search')}`}
                        onChange={(e) => {
                          setKeyword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex col-span-3 sm:col-span-2 sm:pt-0'>
                    <div className='pl-[24px] '>
                      <button className='px-3 py-3 bg-darkGray rounded-md'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='white'
                          strokeWidth='2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17 8l4 4m0 0l-4 4m4-4H3'
                          />
                        </svg>
                      </button>
                    </div>
                    <div className='pl-[19px] pr-[17px] sm:pr-[33px]'>
                      <div className=' py-2 '>
                        <button className=''>
                          <img src={SearchLogo} alt=''/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-6 '>
                <div className='col-span-full sm:col-span-5 overflow-x-auto mt-[36px]'>
                  <table className='text-[14px] w-full'>
                    <thead className='border-b border-gray-200'>
                      <th className='px-4 py-4'>{t('PostConfirmation.index')}</th>
                      <th className='px-4 py-4'>{t('PostConfirmation.name')}</th>
                      <th className='px-4 py-4'>{t('PostConfirmation.confirmed')}</th>
                      <th className='px-4 py-4'>{t('PostConfirmation.printed')}</th>
                      <th className='px-4 py-4'>{t('PostConfirmation.sent')}</th>
                    </thead>
                    {filteredPost && filterPost(filteredPost).map((post, index) => {
                      return (
                        <tbody className='' key={post.id}>
                          <tr className='text-center hover:bg-gray-200' key={post.id}>
                            <td className='px-6 py-4'>
                              {index + 1}
                            </td>
                            <td className='px-6 py-4'>
                              {post?.user?.name}
                            </td>
                            <td className='px-6 py-4 '>
                              {post?.confirmed == null ? <>{t('PostConfirmation.false')}</> : <>{t('PostConfirmation.true')}</>}
                            </td>
                            <td className='px-6 py-4 '>{post?.printed == null ? <>{t('PostConfirmation.false')}</> : <>{t('PostConfirmation.true')}</>}</td>
                            <td className='px-6 py-4 block'>
                              {(post?.sent == null && post.printed == null) ? <>{t('PostConfirmation.false')}</> :
                                (post.printed !== null && post.sent == null) ? (
                                  <button
                                    onClick={() => {
                                      adminServices.printRequest(post.user_id).then((response: any) => {
                                        console.log(response.data);
                                      })
                                        .catch((error: any) => {
                                          console.log('Error getting the post confirms', error);
                                        });}
                                    }
                                    className='bg-green-500 rounded-md px-3 py-2 text-white'>
                                    {t('PostConfirmation.send')}
                                  </button>
                                ) :
                                  <button disabled className='bg-green-500 disabled:bg-gray-400 rounded-md px-5 py-1 text-white'>
                                    {t('PostConfirmation.sent')}
                                  </button>
                              }
                            </td>
                            <td className='px-6 py-4'>
                              <Link
                                to={''}
                                state={{ post }}
                                onClick={() => {console.log('check by id', post); setShowData(true);}}
                                className='px-2 py-1.5 text-sm bg-darkGray text-white rounded-md'
                              >
                                {t('PostConfirmation.generatePostConfirm')}
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
              {showData && (
                <Generated />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { PostConfirmation };

const Generated = () => {
  const [open, setOpen] = useState(false);
  const state = useLocation();
  const navigate = useNavigate();
  const [showPdf, setShowPdf] = useState(false);

  const [code, setCode] = useState('');

  const handleClick = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    for (let i = 0; i < 6; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCode(code);
  };
  const { t } = useTranslation();

  const companyName: string[] = [
    'Firmenname:',
    'Company name:',
    'Nom de l\'entreprise:',
    'Nome dell\'azienda:'
  ];
  const auctionicCodes: string[] = [
    'Ihr AUCTIONIC-Code lautet:',
    'Your AUCTIONIC code is:',
    'Votre code AUCTIONIC est:',
    'Il tuo codice AUCTIONIC è:'
  ];

  const thanks: string[] = [
    'Vielen Dank, dass Sie unser Unternehmen als Ihren bevorzugten Anbieter gewählt haben. Wir schätzen Ihr Geschäft und die Möglichkeit, Sie zu bedienen.',
    'Thank you for choosing our company as your preferred provider. We value your business and appreciate the opportunity to serve you.',
    'Nous vous remercions d\'avoir choisi notre entreprise en tant que fournisseur privilégié. Nous apprécions votre activité et sommes ravis de pouvoir vous servir.',
    'Grazie per aver scelto la nostra azienda come fornitore preferito. Valorizziamo la vostra attività commerciale e apprezziamo l\'opportunità di servirvi.'
  ];

  const questions: string[] = [
    'Haben Sie Fragen? Nehmen Sie Kontakt mit uns auf: support@auctionic.ch',
    'Do you have any questions? Get in touch with us on: support@auctionic.ch',
    'Vous avez des questions ? Contactez-nous à l\'adresse suivante : support@auctionic.ch',
    'Hai domande? Contattaci all\'indirizzo: support@auctionic.ch'
  ];
  const handleSubmit = () => {
    adminServices
      .postConfirmCode(state.state.post.user.id, code)
      .then((response: any) => {
        console.log(response.data.data);
        setOpen(false);
        setShowPdf(true);
      })
      .catch((err: any) => {
        console.log('Error', err);
      })
      .finally(() => {
        console.log('Testest');
      });
  };
  return (
    <div className='space-y-8'>
      <div className='bg-gray-100 w-full sm:w-[80%] p-4 mt-2'>
        <div className='mb-5'>
          <p className='font-bold text-md text-darkGray pb-2'>{t('PostConfirmation.YouRgenerating')}</p>
          <p><span className='font-semibold py-1'>{t('PostConfirmation.companyName')}</span> {state.state?.post?.user?.name}</p>
          <p><span className='font-semibold py-1'>{t('PostConfirmation.address')}</span> {state.state?.post?.user.address.formatted}</p>
        </div>
        <button className='bg-auctionicRed text-white px-4 py-2 rounded-md cursor-pointer appearance-none' onClick={() =>
        {setOpen(true);
          handleClick();
        }}>{t('PostConfirmation.confirmPost')}</button>
        {open && (
          <div id="popup-modal" className="fixed top-0 left-0 right-0 z-50 p-5 flex justify-center items-center overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
            <div className="relative w-full h-full max-w-md md:h-auto">
              <div className="relative bg-white rounded-lg shadow space-y-3">
                <button type="button"
                  onClick={() => setOpen(false)}
                  className="absolute cursor-pointer top-2 right-2.5 text-darkGray bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-hide="popup-modal">
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
                  </svg>
                  <span className="sr-only">{t('PostConfirmation.closeModal')}</span>
                </button>
                <div className="p-6 text-center">
                  <h3 className="mb-5 text-lg font-normal text-darkGray">{t('PostConfirmation.areYousure')}</h3>
                  <button
                    onClick={handleSubmit} data-modal-hide="popup-modal" type="button" className="text-white cursor-pointer bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    {t('PostConfirmation.generate')}
                  </button>
                  <button onClick={() => setOpen(false)} data-modal-hide="popup-modal" type="button" className="text-gray-500 cursor-pointer bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{t('PostConfirmation.cancel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showPdf && (
          <div id="defaultModal" aria-hidden="true"
            className="fixed flex justify-center top-0 left-0 right-0 z-50 p-2 overflow-x-hidden md:inset-0 h-full md:h-full">
            <div className="relative w-full h-full md:h-auto overflow-hidden">
              <div className="relative bg-white rounded-lg shadow">
                <div className="flex items-start justify-between p-4 border-b rounded-t">
                  <button type="button"
                    onClick={() => {setShowPdf(false),
                    navigate(0);
                    }}
                    className="text-gray-400 cursor-pointer bg-auctionicRed hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-hide="defaultModal">
                    <svg aria-hidden="true" className="w-5 h-5" fill="#fff" viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">{t('PostConfirmation.closeModal')}</span>
                  </button>
                </div>
                <PDFViewer style={{ height: '100vh', width: '100%' }}>
                  <Document>
                    <Page size="A4" style={{ width: '100%', height: '100%' }}>
                      <View style={{ ...styles.body }}>
                        <View style={styles.rowImage}>
                          <View style={styles.fill1}>
                            <Image src={logo} />
                            <Text style={styles.textHeader}>
                              AUCTIONIC AG
                            </Text>
                          </View>
                        </View>
                        <View style={styles.row}>
                          <Text style={styles.text}>
                            {state.state?.post?.user?.name}
                          </Text>
                        </View>
                        <View style={styles.rowAddress}>
                          <Text style={styles.text}>
                            {state.state?.post?.user.address.formatted.split(',')[0]}
                          </Text>
                        </View>
                        <View style={styles.rowAddress}>
                          <Text style={styles.text}>
                            {state.state?.post?.user.address.formatted.split(',')[1]}
                          </Text>
                        </View>
                        <View style={styles.rowAddress}>
                          <Text style={styles.text}>
                            {state.state?.post?.user.address.formatted.split(',')[2]},
                            {state.state?.post?.user.address.formatted.split(',')[3]}
                          </Text>
                        </View>
                        <View style={styles.rowCode}>
                          <Text style={styles.textCode}>
                            {state.state?.post?.user && (
                              state.state?.post?.user?.language == 'de' ?
                                auctionicCodes[0]:
                                state.state?.post?.user?.language == 'en' ?
                                  auctionicCodes[1]:
                                  state.state?.post?.user?.language == 'fr' ?
                                    auctionicCodes[2]:
                                    state.state?.post?.user?.language == 'it' ?
                                      auctionicCodes[3]:
                                      auctionicCodes[0]
                            )} {code}
                          </Text>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.fill2} />
                        </View>
                        <View style={styles.rowBanner}>
                          <Text style={styles.textBanner}>
                            {state.state?.post?.user && (
                              state.state?.post?.user?.language == 'de' ?
                                thanks[0]:
                                state.state?.post?.user?.language == 'en' ?
                                  thanks[1]:
                                  state.state?.post?.user?.language == 'fr' ?
                                    thanks[2]:
                                    state.state?.post?.user?.language == 'it' ?
                                      thanks[3]:
                                      thanks[0]
                            )}
                          </Text>
                        </View>
                        <View style={styles.rowContact}>
                          <Text style={styles.textContact}>
                            {state.state?.post?.user && (
                              state.state?.post?.user?.language == 'de' ?
                                questions[0]:
                                state.state?.post?.user?.language == 'en' ?
                                  questions[1]:
                                  state.state?.post?.user?.language == 'fr' ?
                                    questions[2]:
                                    state.state?.post?.user?.language == 'it' ?
                                      questions[3]:
                                      questions[0]
                            )}
                          </Text>
                        </View>
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

