import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchLogo from '../../images/filter.svg';
import x from '../../images/x.svg';
import { Sidenav } from '../Sidenav/Sidenav';
import { adminServices } from '../../services';
import { SpinnerLoader } from '../Common';
import { Debts } from '../../config';
import { baseUrl } from '../../helpers/api';

const DebtRequest = () => {
  const { t } = useTranslation();
  const [debts, setDebts] = useState<Array<Debts>>([]);
  const [filteredDebts, setFilteredDebts] = useState(debts);
  const [keyword, setKeyword,] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [viewClientData, setViewClientData] = useState<Array<number>>([]);

  const filterDebt = (debts: Array<Debts>) => {
    return debts.filter((debt) => debt.user.first_name.toLowerCase().includes(keyword.toLowerCase()));
  };

  useEffect(() => {
    setLoading(true);
    adminServices
      .getDebts()
      .then((response: any) => {
        setDebts(response.data);
        setFilteredDebts(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log('Error getting the debts', error);
      });
  }, []);


  useEffect(() => {
    if ( keyword !== '') {
      setFilteredDebts(filterDebt(debts));
    }
    else { setFilteredDebts(debts); }
  }, [keyword,]);

  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <div className=''>
            <div className='grid grid-cols-5 px-2 sm:px-1'>
              <div className='col-span-2 sm:col-span-3'>
                <div className='flex '>
                  <input
                    type='search'
                    className='border border-[#ABABAC] rounded-md px-3 w-full h-[48px] '
                    placeholder={`${t('admin.search')}`}
                    onChange={(e) => {
                      setKeyword(e.target.value);}
                    }
                  />
                </div>
              </div>
              <div className='flex col-span-3 sm:col-span-2 sm:pt-0'>
                <div className='pl-[24px] '>
                  <button className='px-3 py-3 bg-darkGray rounded-md'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='white'
                      strokeWidth='2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M17 8l4 4m0 0l-4 4m4-4H3'
                      />
                    </svg>
                  </button>
                </div>
                <div className='pl-[19px] pr-[17px] sm:pr-[33px]'>
                  <div className=' py-2 '>
                    <button className='' onClick={handleOpenFilter}>
                      <img src={SearchLogo} alt='' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openFilter ? (
            <div className=''>
              <div className='pt-[36px] px-3 sm:px-1'>
                <ul className='flex space-x-10'>
                  <li>
                    <p>{t('admin.sort')}</p>
                  </li>
                  <li>
                    <button>{t('admin.alphabetical')}</button>
                  </li>
                  <li>
                    <button>{t('admin.dateRegistered')}</button>
                  </li>
                </ul>
              </div>
              <div className='pt-[28px] grid sm:grid-cols-5 grid-cols-1 px-3 sm:px-1'>
                <div className='block'>
                  <div className='block'>
                    <input type='checkbox' className='w-[14px] h-[14px]' />
                    <label className='text-[14px] pl-1'>
                      {t('admin.sold')}
                    </label>
                  </div>
                  <div className='block'>
                    <input type='checkbox' className='w-[14px] h-[14px]' />
                    <label className='text-[14px] pl-1'>
                      {t('admin.postedByIndividual')}
                    </label>
                  </div>
                </div>
                <div className='block'>
                  <div className='block'>
                    <input type='checkbox' className='w-[14px] h-[14px]' />
                    <label className='text-[14px] pl-1'>
                      {t('admin.inProcess')}
                    </label>
                  </div>
                  <div className='block'>
                    <input type='checkbox' className='w-[14px] h-[14px]' />
                    <label className='text-[14px] pl-1'>
                      {t('admin.postedByAgency')}
                    </label>
                  </div>
                </div>
                <div className='block'>
                  <div className='block'>
                    <input type='checkbox' className='w-[14px] h-[14px]' />
                    <label className='text-[14px] pl-1'>
                      {t('admin.listed')}
                    </label>
                  </div>
                </div>
                <div className='block py-2'>
                  <button className='bg-red-600 text-white text-[12px] rounded-md px-7 py-2'>
                    {t('admin.clearFilter')}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className='grid grid-cols-6 '>
            {loading ? (<div className='flex justify-center col-span-5'><SpinnerLoader /></div>) :
              <div className='col-span-full sm:col-span-5 overflow-x-auto mt-[36px]'>
                <table className='text-[14px] w-full'>
                  <thead className='border-b border-gray-200 '>
                    <th className='px-4 py-4'>{t('DebtRequest.firstname')}</th>
                    <th className='px-4 py-4'>{t('DebtRequest.lastname')}</th>
                    <th className='px-4 py-4'>{t('DebtRequest.status')}</th>
                    <th className='px-4 py-4'>{t('DebtRequest.docType')}</th>
                    <th className='px-4 py-4'>{t('admin.moreInfo')}</th>
                  </thead>
                  <tbody className=''>
                    {filteredDebts && filterDebt(filteredDebts)?.map((debt) => (
                      <>
                        <tr className={`text-center hover:bg-gray-200 ${viewClientData.includes(debt.id) ? 'bg-red-400 hover:bg-red-400' : 'bg-white'}`} key={debt.id}>
                          <td className='px-6 py-4'>
                            {debt.user.first_name}
                          </td>
                          <td className='px-6 py-4 '>
                            {debt.user.last_name}
                          </td>
                          <td className='px-6 py-4 block'>
                            <p className='capitalize'>{debt.status}</p>
                          </td>
                          <td className='px-6 py-4 capitalize '>{debt.id_document}</td>
                          <td className='px-6 py-4'>
                            <button
                              className='px-1 py-1 text-sm bg-darkGray text-white rounded-md'
                              onClick={() => {
                                if (viewClientData.includes(debt.id)) {
                                  setViewClientData(
                                    viewClientData.filter(
                                      (_id: any) => debt.id !== _id
                                    )
                                  );
                                } else {
                                  setViewClientData([
                                    debt.id,
                                  ]);
                                }
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-6 w-6'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='white'
                                strokeWidth='2'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M14 5l7 7m0 0l-7 7m7-7H3'
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                        {viewClientData.includes(debt.id) && (
                          <tr className='text-center'>
                            <td colSpan={5} className='col-span-3'>
                              <div className='bg-darkGray rounded-lg grid grid-cols-1'>
                                <div className='flex justify-end pt-4 pr-5'>
                                  <button
                                    onClick={() => {
                                      if (viewClientData.includes(debt.id)) {
                                        setViewClientData(
                                          viewClientData.filter(
                                            (_id: any) => debt.id !== _id
                                          )
                                        );
                                      }
                                      else {
                                        setViewClientData([
                                          debt.id,
                                        ]);
                                      }
                                    }}
                                  >
                                    <img src={x} alt='' className='' />
                                  </button>
                                </div>
                                <div className='pl-[25px] pr-[30px] pb-[32px]'>
                                  <div className='block text-white '>
                                    <p className='text-[22px] font-medium text-left'>
                                      {debt.user.first_name} {debt.user.last_name}
                                    </p>
                                  </div>
                                  <div className={'grid grid-cols-3 text-left text-white pt-3 text-md'}>
                                    <p>Email: {debt.user.email}</p>
                                    <p>Phone: {debt.user.phone}</p>
                                    <p>Address: {debt.user.address.formatted}</p>
                                    <p className='pt-3'>Birdth date: {debt.user.birthday}</p>
                                  </div>
                                  <hr className=' border-gray-400 mt-[40px]' />
                                  <div className='block text-white pt-3 '>
                                    <p className='text-base text-auctionicRed font-medium text-left'>
                                    Payment details
                                    </p>
                                  </div>
                                  <div className={'grid grid-cols-3 text-left text-white pt-3 text-md'}>
                                    <p>Payment status: {debt?.payment?.status}</p>
                                    <p>Price: {debt?.payment?.price}</p>
                                    <p>Document no: {debt?.payment?.document_nr}</p>
                                  </div>
                                  <hr className=' border-gray-400 mt-[40px]' />
                                  <div className='block text-white pt-3 '>
                                    <p className='text-base text-white font-medium text-left'>
                                    Identification with : <span className='capitalize text-auctionicRed'>{debt.id_document}</span>
                                    </p>
                                  </div>
                                  {debt.id_document === 'passport' ? (
                                    <div className='flex justify-center pt-5'>
                                      <img className='h-[320px]' src={`${baseUrl}/${debt?.passport}`} alt={'Passport'} />
                                    </div>
                                  ) : (
                                    <div className={'grid grid-cols-2 text-center gap-x-5'}>
                                      <div className='block pt-5 space-y-2'>
                                        <img src={`${baseUrl}/${debt?.id_card_front}`} width={'450px'} height={'500px'} alt={'ID card back'} />
                                        <p className='text-white italic'>Front image</p>
                                      </div>
                                      <div className='block pt-5 space-y-2'>
                                        <img src={`${baseUrl}/${debt?.id_card_back}`} width={'450px'} height={'500px'} alt={'ID card back'} />
                                        <p className='text-white italic'>Back image</p>
                                      </div>
                                    </div>
                                  )}
                                  <hr className=' border-gray-400 mt-[40px]' />
                                  <div className='block text-white pt-3 '>
                                    <p className='text-base text-white font-medium text-left pb-2'>
                                      Signature:
                                    </p>
                                    {debt.signature !== null ?
                                      <div className='flex justify-center pt-5 bg-gray-200'>
                                        <img className='h-[320px]' src={`${baseUrl}/${debt?.signature}`} alt={'Signature'} />
                                      </div>
                                      : <p className='text-base text-white font-medium text-left'>
                                      No signature
                                      </p>}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export { DebtRequest };
