import { auctionicApi } from '../helpers/api';
import { headers } from '../helpers/headers';

// const userLogin = (email: string, password: string) => {
//   const body = { email, password };
//   const config = { headers: headers.getHeaders() };
//   return auctionicApi.post('/login', body, config);
// };

const userAuth = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/', config);
};

export const userServices = {
  userAuth,
};
