import React from 'react';
import { adminServices } from '../../services';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { utils } from '../../helpers/utils';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Agreements } from './Agreements';

const WaitingToAccept = ({
  viewPropertyData,
  setViewPropertyData,
}: {
  viewPropertyData: any;
  setViewPropertyData: any;
}) => {
  const { t } = useTranslation();
  const [waitingAgreements, setWaitingAgreements] = React.useState<Array<any>>(
    []
  );
  React.useEffect(() => {
    adminServices
      .getWaitingAgreements()
      .then((response) => {
        setWaitingAgreements(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  console.log(waitingAgreements);

  function convertUTCDateToLocalDate(date: string | Date) {
    const _date = new Date(date);
    const newDate = new Date(
      _date.getTime() + _date.getTimezoneOffset() * 60 * 1000
    );
    newDate.setHours(_date.getHours() - _date.getTimezoneOffset() / 60);
    return newDate;
  }

  const timeToAcceptTheBid = (created_at: any) => {
    const dayCreated = new Date(created_at);
    const dayCreated1 = convertUTCDateToLocalDate(dayCreated);
    dayCreated1.setDate(dayCreated1.getDate() + 10);
    return dayCreated1.getTime();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        {days} days, {hours}:{minutes}:{seconds}
      </span>
    );
  };
  
  const handleDiscardAgreement = (id: string) => {
    adminServices
      .agreementDiscard(id)
      .then(() => {
        toast.success('Agreement discarded', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch(() => {
        toast.error('Could not discard the agreement', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <React.Fragment>
      <div className='px-1 '>
        <p className='font-bold'>{t('Agreements.total')}{waitingAgreements.length}</p>
      </div>
      <table className='text-[14px] w-full'>
        <thead className='border-b border-gray-200 '>
          <tr>
            <th className='px-4 py-4'>{t('Agreements.preview')}</th>
            <th className='px-4 py-4'>{t('Agreements.propertyname')} </th>
            <th className='px-4 py-4'>{t('Agreements.timeToaccept')}</th>
            <th className='px-4 py-4'>{t('Agreements.status')}</th>
            <th className='px-4 py-4'>{t('Agreements.moreinfo')}</th>
          </tr>
        </thead>
        {waitingAgreements.map((data: any) => {
          return (
            <tbody key={data.id} className=''>
              <tr
                className={`text-center hover:bg-gray-200 ${
                  viewPropertyData.includes(data.id)
                    ? 'bg-gray-300'
                    : 'bg-white'
                }`}
              >
                <td className='px-6 py-4'>
                  <img
                    width={'50px'}
                    height={'50px'}
                    src={data.property_on_auction.header_pic}
                    alt={''}
                  />
                </td>
                <td className='px-6 py-4'>{data.property_on_auction.title}</td>
                <td className='px-6 py-4'>
                  <Countdown
                    date={timeToAcceptTheBid(data?.created_at)}
                    renderer={renderer}
                    autoStart={true}
                    daysInHours={false}
                  />
                </td>
                <td className='px-6 py-4'>
                  <p className={'flex justify-center'}>
                    <p className={'w-4 h-4  rounded-full bg-yellow-600'}></p>
                  </p>
                </td>
                <td className='px-6 py-4'>
                  <button
                    className={`px-1 py-1 text-sm bg-darkGray text-white rounded-md`}
                    onClick={() => {
                      if (viewPropertyData.includes(data.id)) {
                        setViewPropertyData(
                          viewPropertyData.filter((_id: any) => data.id !== _id)
                        );
                      } else {
                        setViewPropertyData([data.id]);
                      }
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='white'
                      strokeWidth='2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M14 5l7 7m0 0l-7 7m7-7H3'
                      />
                    </svg>
                  </button>
                </td>
              </tr>
              {viewPropertyData.includes(data.id) && (
                <tr className='w-full'>
                  <td colSpan={6} className='col-span-3'>
                    <div className='bg-darkGray rounded-lg grid grid-cols-1'>
                      <div className='flex justify-end pt-4 pr-5'>
                        <button
                          onClick={() => {
                            if (viewPropertyData.includes(data.id)) {
                              setViewPropertyData(
                                viewPropertyData.filter(
                                  (_id: any) => data.id !== _id
                                )
                              );
                            } else {
                              setViewPropertyData([data.id]);
                            }
                          }}
                        >
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M16 13.4587L10.4567 7.986L15.9233 2.46467L13.4587 0L7.984 5.54533L2.444 0.0766667L0 2.52067L5.54733 8.014L0.0766667 13.556L2.52067 16L8.012 10.4547L13.5353 15.9233L16 13.4587Z'
                              fill='white'
                            />
                          </svg>
                        </button>
                      </div>
                      <div className='pl-[25px] pr-[30px] pb-[32px]'>
                        <div className='grid grid-cols-1 sm:grid-cols-2'>
                          <div className='rounded-md pr-5 '>
                            <div className='flex w-[400px] h-[300px]'>
                              <img
                                src={data.property_on_auction?.header_pic}
                                alt=''
                                className=''
                              />
                            </div>
                          </div>
                          <div className=''>
                            <div className='block text-white '>
                              <p className='text-[22px] font-medium'>
                                {data.property_on_auction.title}
                              </p>
                              <p className='text-[20px]'>
                                {utils.numberWithComma(
                                  data?.property_on_auction.current_bid
                                )}{' '}
                                CHF
                              </p>
                              <p className='text-[13px] pt-[12px]'>
                                {data?.property_on_auction.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr className=' border-gray-400 mt-[48px]' />
                        <div className='grid grid-cols-1 sm:grid-cols-1 pt-[26px] text-white'>
                          <div className='col-span-1'>
                            <div className='block'>
                              <div className='flex'>
                                <p className='text-base pt-1'>
                                  {t('Agreements.propertyDetails')}
                                </p>
                              </div>
                              <div className='block sm:flex sm:justify-start sm:space-x-14'>
                                <div className='block space-y-2'>
                                  <div className='flex items-center space-x-5 pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.advertisingId')}
                                    </p>
                                    <p className='text-[14px]'>{data?.id}</p>
                                  </div>
                                  <div className='flex'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.preview')}
                                    </p>
                                    <p className='text-[14px] pl-[15px]'>
                                      {data?.property_on_auction.property_id}
                                    </p>
                                  </div>
                                </div>
                                <div className='block space-y-2'>
                                  <div className='flex items-center pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.address')}
                                    </p>
                                    <p className='text-[14px] pl-[15px] capitalize'>
                                      {data?.property_on_auction.address}
                                    </p>
                                  </div>
                                  <div className='flex'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.currentBid')}
                                    </p>
                                    <p className='text-[14px] pl-[15px]'>
                                      {utils.numberWithComma(
                                        data?.property_on_auction.current_bid
                                      )}{' '}
                                      CHF
                                    </p>
                                  </div>
                                </div>
                                <div className='block space-y-2'>
                                  <div className='flex items-center pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.status')}
                                    </p>
                                    <p className='text-[14px] pl-[15px] capitalize'>
                                      {t('Agreements.waitingforDecision')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='block pt-6'>
                              <div className='flex'>
                                <p className='text-base pt-1'>
                                  {t('Agreements.adsDetails')}
                                </p>
                              </div>
                              <div className='block sm:flex sm:justify-start sm:space-x-24'>
                                <div className='block space-y-2'>
                                  <div className='flex items-center space-x-5 pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.startDate')}
                                    </p>
                                    <p className='text-[14px]'>
                                      {moment(
                                        data?.property_on_auction?.start_date
                                      ).format('DD-MM-YYYY HH:mm:ss')}
                                    </p>
                                  </div>
                                  <div className='flex'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.finishDate')}
                                    </p>
                                    <p className='text-[14px] pl-[15px]'>
                                      {moment(
                                        data?.property_on_auction
                                          ?.finishing_date
                                      ).format('DD-MM-YYYY HH:mm:ss')}
                                    </p>
                                  </div>
                                </div>
                                <div className='block space-y-2'>
                                  <div className='flex items-center pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.plan')}
                                    </p>
                                    <p className='text-[14px] pl-[15px] capitalize'>
                                      {
                                        data?.property_on_auction.payment?.plan
                                          ?.tier
                                      }
                                    </p>
                                  </div>
                                  <div className='flex'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.duration')}
                                    </p>
                                    <p className='text-[14px] pl-[15px]'>
                                      {
                                        data?.property_on_auction.payment?.plan
                                          ?.duration
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='block space-y-2'>
                                  <div className='flex items-center pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.price')}
                                    </p>
                                    <p className='text-[14px] pl-[15px] capitalize'>
                                      {utils.numberWithComma(
                                        data?.property_on_auction.payment?.plan
                                          ?.price
                                      )}{' '}
                                      CHF
                                    </p>
                                  </div>
                                  <div className='flex'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.discount')}
                                    </p>
                                    <p className='text-[14px] pl-[15px]'>
                                      {
                                        data?.property_on_auction.payment
                                          ?.discount
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='block space-y-2'>
                                  <div className='flex items-center pt-2'>
                                    <p className='text-gray-300 text-[14px]'>
                                      {t('Agreements.paymentStatus')}
                                    </p>
                                    <p className='text-[14px] pl-[15px] capitalize'>
                                      {
                                        data?.property_on_auction.payment
                                          ?.status
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='block pt-6'>
                              <div className='flex'>
                                <p className='text-base pt-1'>
                                  {t('Agreements.agreementDetails')}
                                </p>
                              </div>
                              <div className='block sm:flex sm:justify-start sm:space-x-24'>
                                <div className='flex items-center space-x-5 pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('Agreements.createdAt')}
                                  </p>
                                  <p className='text-[14px]'>
                                    {moment(data?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                                  </p>
                                </div>
                              </div>
                              <div className='grid grid-cols-1 sm:grid-cols-2 pt-2.5'>
                                <div className='block'>
                                  <div className='font-bold'>{t('Agreements.buyerInfo')}</div>
                                  <div className='block'>
                                    <p>
                                      {t('Agreements.name')}{' '}
                                      {data.buyer.type === 'individual'
                                        ? data?.buyer?.first_name +
                                          ' ' +
                                          data?.buyer?.last_name
                                        : data?.buyer?.name}
                                    </p>
                                    <p>{t('Agreements.email')} {data.buyer.email}</p>
                                    <p>{t('Agreements.phone')} {data.buyer.phone}</p>
                                  </div>
                                </div>
                                <div className='block'>
                                  <div className='font-bold'>{t('Agreements.sellerInfo')}</div>
                                  <div className='block'>
                                    <p>
                                      {t('Agreements.name')}{' '}
                                      {data.seller.type === 'individual'
                                        ? data?.seller?.first_name +
                                          ' ' +
                                          data?.seller?.last_name
                                        : data?.seller?.name}
                                    </p>
                                    <p>{t('Agreements.email')} {data.seller.email}</p>
                                    <p>{t('Agreements.phone')} {data.seller.phone}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex justify-center pt-[73px]'>
                          <button className='px-6 py-2 bg-red-600 rounded-md text-white shadow-black shadow-sm' onClick={() => handleDiscardAgreement(data?.id)}>
                            {t('Agreements.discard')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          );
        })}
      </table>
    </React.Fragment>
  );
};

export { WaitingToAccept };
