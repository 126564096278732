import React, { useState } from 'react';
import { adminServices } from '../../services';
import { toast } from 'react-toastify';

export interface Plan {
  duration: number;
  price: number;
  id: number;
  description: string;
  tier: string;

}

const PlanList = (props: any) => {
  const { plans } = props;
  console.log('plans1', plans);
  const silver = plans != null ? Object.values(plans.silver) : null;
  const gold = plans != null ? Object.values(plans.gold) : null;
  const platinum = plans != null ? Object.values(plans.platinum) : null;
  const [editing, setEditing] = useState('100');
  const [editedPlan, setEditedPlan] = useState <Plan> ();
  console.log('edited Plan', editedPlan);
  console.log('plans', plans);
  return (
    <div>
      <div>
        SILVER
        <SingleTier items={silver} tierType={'gray-500'} editing={editing} setEditing={setEditing} editedPlan={editedPlan} setEditedPlan={setEditedPlan} />
      </div>
      <div className='pt-[20px]'>
        
        GOLD
        <SingleTier items={gold} tierType={'yellow-300'}  editing={editing} setEditing={setEditing} editedPlan={editedPlan} setEditedPlan={setEditedPlan}/>
      </div>
      <div className='pt-[20px]'>
        PLATINUM
        <SingleTier items={platinum} tierType={'red-400'}  editing={editing} setEditing={setEditing} editedPlan={editedPlan} setEditedPlan={setEditedPlan}/>
      </div>
    </div>
  );
};
const SingleTier = (props:any) => {
  const { items, tierType, editing, setEditing, editedPlan, setEditedPlan } = props;
  const update = (id: any) => {
    adminServices.updatePlan(editedPlan).then(() => {
      toast.success(('Successfully updated plan'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setTimeout(() => {
        window.location.reload();
      },3000);
    }
    ).catch((e:any) => {
      console.log(e);
    }).finally();
    console.log(id);
  };
  return (
    <div className={`bg-${tierType} p-5 rounded-xl text-xl`}>
      {items != null ? (items.map((data:any) => {
        return (
          <div key={data.id} className={`grid grid-cols-6 bg-white items-cetner rounded-md bg-opacity-50 p-2 m-5 justify-between`}>
            <div className='col-span-5 mr-5' >
              <div className='flex justify-between mb-2'>
                <div id='id' className='flex'>
                  Plan id: {data.id}
                </div>
                <div id='description'>
                  Plan description: {data.description}
                </div>
              </div>
              <div className='grid grid-cols-3 justify-between'>
                <div id='name' className=''>
                  Plan name: {data.name}
                </div>
                <div id='duration' className=''>
                  Plan duration: {data.duration}
                </div>
                <div id='price' className=''>
                  <label>Plan price: </label>
                  <input
                    disabled={editing!=data?.id}
                    value={editing ==data?.id ? editedPlan?.price : data?.price }
                    onChange={(e:any) => { setEditedPlan((prev:any) => ({ ...prev, price:e.target.value, })); }}>
                  </input>
                </div>
              </div>
            </div>
            <div id='update' className='flex col-span-1  self-center justify-end mr-5'>
              {editing == 100 && (
                <button className='flex justify-end bg-blue-400 rounded-md p-2' onClick={() => {setEditing(data.id); setEditedPlan(data);}}>
                  Edit
                </button>
              )}
              {editing == data?.id &&
              (
                <div className='flex justify-between'>
                  <button className='flex justify-end bg-green-400 rounded-md p-2' onClick={() => update(data.id)}>
                    Update
                  </button>
                  <button className='flex justify-end bg-red-400 rounded-md p-2' onClick={() => {setEditing(100); setEditedPlan({});}}>
                    Cancel Edit
                  </button>
                </div>
              )
              }
            </div>
          </div>
        );
      })): ''}
    </div>
  );
};

export { PlanList };
