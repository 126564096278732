import { t } from 'i18next';
import React, { useState } from 'react';
import house from '../../images/houser1.png';
import x from '../../images/x.svg';

const AdRevenue = () => {
  const [openAdRevenue, setOpenAdRevenue] = useState(false);
  const handleOpenAdRevenue = () => {
    setOpenAdRevenue(!openAdRevenue);
  };
  return (
    <div className='pt-[49px] text-darkGray space-y-[28px] sm:pr-20'>
      {openAdRevenue ? (
        <div className='bg-darkGray rounded-lg grid grid-cols-1'>
          <div className='flex justify-end pt-4 pr-5'>
            <button onClick={handleOpenAdRevenue}>
              <img src={x} alt='' className='' />
            </button>
          </div>
          <div className='pl-[25px] pr-[30px] pb-[32px]'>
            <div className='grid grid-cols-1 sm:grid-cols-2'>
              <div className='rounded-md pr-5 '>
                <div className='flex  '>
                  <img src={house} alt='' className='' />
                </div>
              </div>
              <div className=''>
                <div className='block text-white '>
                  <p className='text-[22px] font-medium'>451 Bruns St.</p>
                  <p className='text-[20px]'>1,2500 CH -</p>
                  <p className='text-[12px] pt-[12px]'>
                    Venez découvrir ce bel appartement denv. 81 m2 situé dans
                    lhyper-centre de Genève. Sa situation idéale vous permet
                    davoir accès a tout ce dont vous aurez besoin a pied ou
                    grâce aux nombreux transports en communs, bus, tram et la
                    gare Cornavin à moins de 10 min à pied ! Au coeur du
                    quartier des Pâquis, vous pourrez profiter de la vie animée
                    du quartier tout en ayant
                  </p>
                  <div className='flex justify-end pt-[53px]'>
                    <button className='bg-white text-darkGray text-[14px] rounded-md px-5 py-2 hover:text-red-600'>
                      {t('admin.goToListingPage')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr className=' border-gray-400 mt-[48px]' />
            <div className='grid grid-cols-1 sm:grid-cols-2 pt-[26px] text-white'>
              <div className='col-span-1'>
                {/* Sold by */}
                <div className='block'>
                  <div className='flex'>
                    <p className='text-[14px] pt-1'>{t('admin.soldBy')}</p>
                    <p className='text-[18px] pl-[9px]'>John Doe</p>
                  </div>
                  <div className='block sm:flex sm:justify-between'>
                    <div className='block'>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-1'>
                          {t('admin.email')}
                        </p>
                        <p className='text-[14px] pl-[15px]'>
                          johnnydoe@gmail.com
                        </p>
                      </div>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>
                          {t('admin.type')}
                        </p>
                        <p className='text-[14px] pl-[15px]'>Individual</p>
                      </div>
                    </div>
                    <div className='block'>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>
                          {t('admin.number')}
                        </p>
                        <p className='text-[14px] pl-[15px]'>+042 552 5515</p>
                      </div>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>ID</p>
                        <p className='text-[14px] pl-[15px]'>#1255125</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Bought by */}
                <div className='block pt-[41px]'>
                  <div className='flex'>
                    <p className='text-[14px] pt-0.5'>{t('admin.boughtBy')}</p>
                    <p className='text-[18px] pl-[9px]'>John Smith</p>
                  </div>
                  <div className='block sm:flex sm:justify-between text-center'>
                    <div className='block'>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>
                          {t('admin.email')}
                        </p>
                        <p className='text-[14px] pl-[15px]'>
                          johnsmith@gmail.com
                        </p>
                      </div>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>
                          {t('admin.type')}
                        </p>
                        <p className='text-[14px] pl-[15px]'>Individual</p>
                      </div>
                    </div>
                    <div className='block'>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>
                          {t('admin.email')}
                        </p>
                        <p className='text-[14px] pl-[15px]'>+042 662 5515</p>
                      </div>
                      <div className='flex'>
                        <p className='text-gray-400 text-[12px] pt-0.5'>ID</p>
                        <p className='text-[14px] pl-[15px]'>#55256</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-span-1 pt-6 sm:pt-0 sm:pl-[92px]'>
                <div className='block'>
                  <h1 className='text-[18px]'>{t('admin.revenue')}</h1>
                  <div className='flex'>
                    <p className='text-[18px]'>CHF 190.-</p>
                    <p className='text-gray-400 text-[12px] pl-[12px] pt-1.5'>
                      18/01/2022
                    </p>
                  </div>
                  <div className='flex'>
                    <p className='text-[18px] pt-1'>CHF 390.-</p>
                    <p className='text-gray-400 text-[12px] pl-[12px] pt-1.5'>
                      22/02/2022
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center pt-[73px]'>
              <button className='px-6 py-2 bg-red-600 rounded-md text-white shadow-black shadow-sm'>
                {t('admin.generatePDF')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='overflow-y-auto '>
          <div className='bg-[#F5F5F5] rounded-lg mb-[28px] mx-2'>
            <div className='grid sm:grid-cols-3 grid-cols-1 divide-y-0 sm:divide-x-2 divide-gray-400 py-4 space-y-5 sm:space-y-0'>
              <div className='flex px-4 '>
                <div className='rounded-lg '>
                  <img
                    src={house}
                    alt=''
                    className='rounded-lg h-[112px] w-[112px]'
                  />
                </div>
                <div className='block self-center pt-[11px] pl-4'>
                  <p className='text-[18px]'>451 Bruns St.</p>
                  <p className='text-[14px] pt-[11px]'>1,2500 CH -</p>
                </div>
              </div>
              <div className='flex sm:justify-center space-x-[11px] self-center px-3 sm:px-0'>
                <div className='block'>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.postedBy')}
                  </p>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.plan')}
                  </p>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.revenue')}
                  </p>
                </div>
                <div className='block'>
                  <p className='text-[16px]'>John Doe</p>
                  <p className='text-[16px]'>Gold</p>
                  <p className='text-[16px] text-red-600'>CHF 390.-</p>
                </div>
              </div>
              <div className='flex justify-end sm:justify-center space-x-[11px] self-center pt-5 sm:py-5 px-3 sm:px-0 '>
                <button
                  className='bg-darkGray px-4 py-1 rounded-md hover:px-12'
                  onClick={handleOpenAdRevenue}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M17 8l4 4m0 0l-4 4m4-4H3'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className='bg-[#F5F5F5] rounded-lg mb-[28px] mx-2'>
            <div className='grid sm:grid-cols-3 grid-cols-1 divide-y-0 sm:divide-x-2 divide-gray-400 py-4 space-y-5 sm:space-y-0'>
              <div className='flex px-4 '>
                <div className='rounded-lg '>
                  <img
                    src={house}
                    alt=''
                    className='rounded-lg h-[112px] w-[112px]'
                  />
                </div>
                <div className='block self-center pt-[11px] pl-4'>
                  <p className='text-[18px]'>451 Bruns St.</p>
                  <p className='text-[14px] pt-[11px]'>1,2500 CH -</p>
                </div>
              </div>
              <div className='flex sm:justify-center space-x-[11px] self-center px-3 sm:px-0'>
                <div className='block'>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.postedBy')}
                  </p>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.plan')}
                  </p>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.revenue')}
                  </p>
                </div>
                <div className='block'>
                  <p className='text-[16px]'>John Doe</p>
                  <p className='text-[16px]'>Gold</p>
                  <p className='text-[16px] text-red-600'>CHF 390.-</p>
                </div>
              </div>
              <div className='flex justify-end sm:justify-center space-x-[11px] self-center pt-5 sm:py-5 px-3 sm:px-0 '>
                <button
                  className='bg-darkGray px-4 py-1 rounded-md hover:px-12'
                  onClick={handleOpenAdRevenue}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M17 8l4 4m0 0l-4 4m4-4H3'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className='bg-[#F5F5F5] rounded-lg mb-[28px] mx-2'>
            <div className='grid sm:grid-cols-3 grid-cols-1 divide-y-0 sm:divide-x-2 divide-gray-400 py-4 space-y-5 sm:space-y-0'>
              <div className='flex px-4 '>
                <div className='rounded-lg '>
                  <img
                    src={house}
                    alt=''
                    className='rounded-lg h-[112px] w-[112px]'
                  />
                </div>
                <div className='block self-center pt-[11px] pl-4'>
                  <p className='text-[18px]'>451 Bruns St.</p>
                  <p className='text-[14px] pt-[11px]'>1,2500 CH -</p>
                </div>
              </div>
              <div className='flex sm:justify-center space-x-[11px] self-center px-3 sm:px-0'>
                <div className='block'>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.postedBy')}
                  </p>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.plan')}
                  </p>
                  <p className='text-gray-600 text-[14px] flex justify-end pt-0.5'>
                    {t('admin.revenue')}
                  </p>
                </div>
                <div className='block'>
                  <p className='text-[16px]'>John Doe</p>
                  <p className='text-[16px]'>Gold</p>
                  <p className='text-[16px] text-red-600'>CHF 390.-</p>
                </div>
              </div>
              <div className='flex justify-end sm:justify-center space-x-[11px] self-center pt-5 sm:py-5 px-3 sm:px-0 '>
                <button
                  className='bg-darkGray px-4 py-1 rounded-md hover:px-12'
                  onClick={handleOpenAdRevenue}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M17 8l4 4m0 0l-4 4m4-4H3'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export { AdRevenue };
