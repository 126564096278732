import React, { useEffect, useState } from 'react';
import { Sidenav } from '../Sidenav/Sidenav';
import { adminServices } from '../../services';
import { useTranslation } from 'react-i18next';
import { Error } from '../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerLoader } from '../Common';
import check from '../../images/check.svg';
import { useNavigate } from 'react-router-dom';


const Errors = () => {
  const [errors, setErrors] = useState<Array<Error>>([]);
  const [filteredErrors, setFilteredErrors] = useState(errors);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const filterErrors = (errors: any) => {
    const errors1 = errors?.filter((err: any) => err.log.toLowerCase().includes(keyword.toLowerCase()));
    return errors1.sort((a:any, b:any) => b.id - a.id);
  };

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    adminServices
      .getErrors()
      .then((response: any) => {
        setErrors(response.data);
        setFilteredErrors(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log('Error getting the errors', error);
      });
  }, []);

  useEffect(() => {
    if ( keyword !== '') {
      setFilteredErrors(filterErrors(errors));
    }
    else { setFilteredErrors(errors); }
  }, [keyword,]);

 
  const resolveConflict = (error_id: any) => {
    adminServices.resolveConflict(error_id)
      .then((response: any) => {
        console.log(response.data);
        toast.success('Resolved successfully!', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          navigate(0);
        }, 2000);

      })
      .catch((error: any) => {
        console.log('Error getting the errors', error);
        toast.error('Resolve Error', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <div className='bg-white min-h-screen'>
      <ToastContainer autoClose={4000} />
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px] px-9'>
          <div className='flex '>
            <input
              type='search'
              className='border border-[#ABABAC] rounded-lg py-3 px-3 w-1/2'
              placeholder='Search errors by logs'
              onChange={(e) => {
                setKeyword(e.target.value);}
              }
            />
          </div>
          {loading ? (<SpinnerLoader />) :
            <div className='col-span-full sm:col-span-5 border border-[#ABABAC] rounded-lg overflow-x-auto mt-[36px] mb-10'>
              <table className='w-full'>
                <thead className='border-b border-gray-200 '>
                  <th className='px-6 py-4'>{t('error.indexSubject')}</th>
                  <th className='px-6 py-4'>{t('error.logs')}</th>
                  <th className='px-6 py-4'>{t('error.resolved')}</th>
                </thead>
                <>
                  {filteredErrors && filterErrors(filteredErrors)?.map((error: any, index:any) => {
                    return (
                      <tbody className='' key={error.id}>
                        <tr className='border-b border-b-gray-300 hover:bg-gray-200'>
                          <td className='px-6 py-4'>{error.id}. {error.subject}</td>
                          <td className='px-6 py-4'>{error.log}</td>
                          <td className='px-6 py-4 text-center'>{error.resolved == true ?
                            <button className='p-1 rounded-sm bg-[#11D900]'>
                              <img src={check} alt='' className=''/>
                            </button>
                            :
                            <div className='text-center space-y-1'><p className='text-red-500 font-extrabold'>X</p>
                              <button onClick={() => {resolveConflict(error.id);}} className='bg-green-400 rounded-md px-4 py-1 text-white'>{t('error.resolve')}</button>
                            </div>
                          }
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </>
              </table>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export { Errors };
