import React from 'react';
import { PropsSale } from './Properties';
import { useTranslation } from 'react-i18next';
import { adminServices } from '../../services/adminServices';
import { toast } from 'react-toastify';

const Sale = (props: PropsSale) => {
  const { viewPropertyData, setViewPropertyData, filteredSaleProperties, filterSale, handleGetInvoice, isButtonLoading, user, getUser, } = props;
  const { t } = useTranslation();
 
  const createEntity = (id: number, type: string) => {
    adminServices.createComparisEntity(id, type).then(
      () => {
        toast.success('Successfully created entity');
      }
    ).catch(
      () => {
        toast.error('Error creating entity');
      }
    );
  };

  const updateEntity = (id: number, type: string) => {
    adminServices.updateComparisEntity(id, type).then(
      () => {
        toast.success('Successfully updated entity');
      }
    ).catch(
      () => {
        toast.error('Error updating entity');
      }
    );
  };

  const deleteEntity = (id:number, type: string) => {
    adminServices.deleteComparisEntity(id, type).then(
      () => {
        toast.success('Entity deleted');
      }
    ).catch(() => {
      toast.error('Error deleting entity');
    });
  };

  return (
    <>
      {filteredSaleProperties && filterSale(filteredSaleProperties)?.map((prop: any) => (
        <tbody className='' key={prop.id}>
          <>
            <tr className={`text-center hover:bg-gray-200 ${viewPropertyData.includes(prop.id) ? 'bg-red-400 hover:bg-red-400' : 'bg-white'}`} key={prop.id}>
              <td className='px-6 py-4'><img width={'50px'} height={'50px'} src={prop.header_pic} alt={''} /> </td>
              <td className='px-6 py-4'>{prop.title}</td>
              <td className='px-6 py-4' colSpan={2}>{prop.address}</td>
              <td className='px-6 py-4'>
                {(prop.status == 1 || prop.status == 2 || prop.status == 3) ?
                  <div className={'w-4 h-4  rounded-full bg-yellow-300'}></div>
                  : prop.status == 4 ?
                    <div className={'w-4 h-4  rounded-full bg-green-400'}></div>
                    : (prop.status == 5 || prop.status == 7) ?
                      <div className={'w-4 h-4  rounded-full bg-red-500'}></div>
                      : prop.status == 6 ?
                        <div className={'w-4 h-4  rounded-full bg-yellow-600'}></div>
                        : (prop.status == 8 || prop.status == 9) ?
                          <div className={'w-4 h-4  rounded-full bg-gray-500'}></div>
                          : prop.status == 10 ?
                            <div className={'w-4 h-4  rounded-full bg-black'}></div>
                            : prop.status == 99 ?
                              <div className={'w-4 h-4  rounded-full bg-fuchsia-500'}></div>
                              : null
                }
              </td>
              <td className='px-6 py-4'>
                <button
                  className={`px-1 py-1 text-sm bg-darkGray text-white rounded-md`}
                  onClick={() => {
                    if (viewPropertyData.includes(prop.id)) {
                      setViewPropertyData(
                        viewPropertyData.filter(
                          (_id: any) => prop.id !== _id
                        )
                      );
                    } else {
                      setViewPropertyData([
                        prop.id,
                      ]);
                    }
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M14 5l7 7m0 0l-7 7m7-7H3'
                    />
                  </svg>
                </button>
              </td>
            </tr>
            {viewPropertyData.includes(prop.id) && (
              <tr>
                <td colSpan={6} className='col-span-3'>
                  <div className='bg-darkGray rounded-lg grid grid-cols-1'>
                    <div className='flex justify-end pt-4 pr-5'>
                      <button
                        onClick={() => {
                          if (viewPropertyData.includes(prop.id)) {
                            setViewPropertyData(
                              viewPropertyData.filter(
                                (_id: any) => prop.id !== _id
                              )
                            );
                          }
                          else {
                            setViewPropertyData([
                              prop.id,
                            ]);
                          }
                        }}
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16 13.4587L10.4567 7.986L15.9233 2.46467L13.4587 0L7.984 5.54533L2.444 0.0766667L0 2.52067L5.54733 8.014L0.0766667 13.556L2.52067 16L8.012 10.4547L13.5353 15.9233L16 13.4587Z" fill="white"/>
                        </svg>
                      </button>
                    </div>
                    <div className='pl-[25px] pr-[30px] pb-[32px]'>
                      <div className='grid grid-cols-1 sm:grid-cols-2'>
                        <div className='rounded-md pr-5 '>
                          <div className='flex w-[400px] h-[300px]'>
                            <img src={prop?.header_pic} alt='' className='' />
                          </div>
                        </div>
                        <div className=''>
                          <div className='block text-white '>
                            <p className='text-[22px] font-medium'>{prop.title}</p>
                            <p className='text-[20px]'>{prop?.price} CHF</p>
                            <p className='text-[13px] pt-[12px]'>
                              {prop?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr className=' border-gray-400 mt-[48px]' />
                      <div className='mt-5'>
                        <button
                          className={'bg-auctionicRed px-8 py-2 rounded-md text-lg text-white'}
                          onClick={() => {getUser(prop?.property?.user?.id);}}
                        >
                          {t('payments.getUserDetails')}
                        </button>
                      </div>
                      { user?.id == prop?.property?.user?.id && (
                        <div className='bg-gray-500 my-4 p-3 rounded-md text-lg text-white'>
                          <p>
                            {t('payments.name')}: {user?.name != null ? user?.name
                              :
                              user?.first_name + ' ' + user?.last_name
                            }
                          </p>
                          <p>{t('payments.address')}: {user?.address.formatted}</p>
                          <p>{t('payments.email')}: {user?.email}</p>
                          <p>{t('payments.phoneNumber')}: {user?.phone}</p>
                          <p>{t('payments.telephone')}: {user?.telephone}</p>
                        </div>
                      )}
                      <hr className=' border-gray-400 mt-5' />
                      <div className='grid grid-cols-1 sm:grid-cols-1 pt-[26px] text-white'>
                        <div className='col-span-1'>
                          <div className='block'>
                            <div className='flex'>
                              <p className='text-base pt-1'>{t('properties.propertyDetails')}</p>
                            </div>
                            <div className='block sm:flex sm:justify-start sm:space-x-20'>
                              <div className='block space-y-2'>
                                <div className='flex items-center space-x-5 pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.advertisingId')}
                                  </p>
                                  <p className='text-[14px]'>
                                    {prop?.id}
                                  </p>
                                </div>
                                <div className='flex'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.propertyId')}
                                  </p>
                                  <p className='text-[14px] pl-[15px]'>{prop?.property_id}</p>
                                </div>
                              </div>
                              <div className='block space-y-2'>
                                <div className='flex items-center pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.propertyAddress')}
                                  </p>
                                  <p className='text-[14px] pl-[15px] capitalize'>{prop?.address}</p>
                                </div>
                                <div className='flex'>
                                  <p className='text-gray-300 text-[14px]'>{t('properties.price')}</p>
                                  <p className='text-[14px] pl-[15px]'>{prop?.price} CHF</p>
                                </div>
                              </div>
                              <div className='block space-y-2'>
                                <div className='flex items-center pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.propertyStatus')}
                                  </p>
                                  <p className='text-[14px] pl-[15px] capitalize'>
                                    {prop?.status === 1 ? t('properties.status1') :
                                      prop?.status === 2 ? t('properties.status2') :
                                        prop.status === 3 ? t('properties.status3') :
                                          prop.status === 4 ? t('properties.status4') :
                                            prop.status === 5 ? t('properties.status5') :
                                              prop.status === 6 ? t('properties.status6') :
                                                prop.status === 7 ? t('properties.status7') :
                                                  prop.status === 8 ? t('properties.status8') :
                                                    prop.status === 9 ? t('properties.status9') :
                                                      prop.status === 10 ? t('properties.status10') :
                                                        prop.status === 99 ? t('properties.status99') : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='block pt-6'>
                            <div className='flex'>
                              <p className='text-base pt-1'>{t('properties.advertisementDetails')}</p>
                            </div>
                            <div className='block sm:flex sm:justify-start sm:space-x-24'>
                              <div className='block space-y-2'>
                                <div className='flex items-center space-x-5 pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.startingDate')}
                                  </p>
                                  <p className='text-[14px]'>
                                    {prop?.start_date}
                                  </p>
                                </div>
                                <div className='flex'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.finishDate')}
                                  </p>
                                  <p className='text-[14px] pl-[15px]'>{prop?.finishing_date}</p>
                                </div>
                              </div>
                              <div className='block space-y-2'>
                                <div className='flex items-center pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.plan')}
                                  </p>
                                  <p className='text-[14px] pl-[15px] capitalize'>{prop?.payment?.plan?.tier}</p>
                                </div>
                                <div className='flex'>
                                  <p className='text-gray-300 text-[14px]'>{t('properties.duration')}</p>
                                  <p className='text-[14px] pl-[15px]'>{prop?.payment?.plan?.duration}</p>
                                </div>
                              </div>
                              <div className='block space-y-2'>
                                <div className='flex items-center pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.price')}
                                  </p>
                                  <p className='text-[14px] pl-[15px] capitalize'>{prop?.payment?.plan?.price} CHF</p>
                                </div>
                                <div className='flex'>
                                  <p className='text-gray-300 text-[14px]'>{t('properties.discount')}</p>
                                  <p className='text-[14px] pl-[15px]'>{prop?.payment?.discount}</p>
                                </div>
                              </div>
                              <div className='block space-y-2'>
                                <div className='flex items-center pt-2'>
                                  <p className='text-gray-300 text-[14px]'>
                                    {t('properties.paymentStatus')}
                                  </p>
                                  <p className='text-[14px] pl-[15px] capitalize'>{prop?.payment?.status}</p>
                                </div>
                                <div className='border-1 border-gray-50 block space-y-2'>
                                  <div className='flex items-center'>
                                    <p className='text items-center pt-2'>
                                      Comparis
                                    </p>
                                    <button className={`ml-3 px-2 rounded-md w-100 ${prop?.status == 4 ? 'bg-green-900'  : 'bg-red-500'}`}>
                                      {prop?.status == 4 ? 'Yes' : 'No'}
                                    </button>
                                  </div>
                                  <div className='flex'>
                                    <p>Comparis Live</p>
                                    <button className={`ml-3 px-2 rounded-md w-100 ${prop?.comparis_live ? 'bg-green-900'  : 'bg-red-500'}`}>
                                      {prop?.comparis_live ? 'Yes' : 'No'}
                                    </button>
                                  </div>
                                  <div className='flex'>
                                    <p>Status</p>
                                    <button
                                      className={`ml-3 px-2 rounded-md ${(prop?.status == 4 && prop?.comparis_live) || (prop?.status != 4 && !prop?.comparis_live) ? 'bg-green-900'  : 'bg-red-500'}`}
                                      disabled={(prop?.status == 4 && prop?.comparis_live) || (prop?.status != 4 && !prop?.comparis_live)}
                                      onClick={() => {
                                        if (prop.status == 4 && !prop?.comparis_live) {
                                          createEntity(prop.id, 'sale');
                                        }
                                        if (prop.staus !=4 && prop.comparis_live) {
                                          deleteEntity(prop.id, 'sale');
                                        }
                                      }}
                                    >
                                      {(prop?.status == 4 && prop?.comparis_live) || (prop?.status != 4 && !prop?.comparis_live) ? 'OK' : 'Update'}
                                    </button>
                                  </div>
                                  <div className='flex'>
                                    <p>Update</p>
                                    <button
                                      className='ml-3 px-2 rounded-md bg-blue-500'
                                      onClick={() => {
                                        updateEntity(prop.id, 'sale');
                                      }}
                                    >
                                      Update data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-center pt-[73px]'>
                        <button
                          disabled={isButtonLoading}
                          onClick={() => {handleGetInvoice(prop.payment.id);}}
                          className='px-6 py-2 bg-red-600 rounded-md text-white shadow-black shadow-sm'>
                          {
                            isButtonLoading && (
                              <svg role="status" className="inline w-4 h-4 mr-3 text-white animate-spin"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"/>
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"/>
                              </svg>
                            )
                          }
                          {
                            isButtonLoading ? t('properties.generateInvoice') : t('properties.generateInvoicePdf')
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </>
        </tbody>
      ))}
    </>
  );
};
export { Sale };
