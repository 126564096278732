import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionPlans, RentPlans, SalePlans } from '../../config';
import { adminServices } from '../../services';
// import { Sale } from './Sale';
import { Sidenav } from '../Sidenav/Sidenav';
import { PlanList } from './PlanList';
// import { Rent } from './Rent';

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState('auction');
  const [auction, setAuction] = useState<AuctionPlans[]>();
  const [sale, setSale] = useState<SalePlans[]>();
  const [rent, setRent] = useState<RentPlans[]>();
  const { t } = useTranslation();
  //  useEffect for Auction plans
  useEffect(() => {
    adminServices
      .subscriptionAuction()
      .then((response:any) => {
        window.scrollTo(0, 0);
        setAuction(response.data);
      })
      .catch((error: any) => {
        console.log('Failed to get auction the plans', error);
      });
  }, []);

  // useEffect for Sale plans
  useEffect(() => {
    adminServices
      .subscriptionSale()
      .then((response:any) => {
        window.scrollTo(0, 0);
        setSale(response.data);
      })
      .catch((error: any) => {
        console.log('Failed to get the rent plan', error);
      });
  }, []);

  // useEffect for Rent plans
  useEffect(() => {
    adminServices
      .subscriptionRent()
      .then((response:any) => {
        window.scrollTo(0, 0);
        setRent(response.data);
      })
      .catch((error: any) => {
        console.log('Failed to get the rent plan', error);
      });
  }, []);
  console.log(auction);
  console.log(sale);
  console.log(rent);

  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <ul className='grid grid-cols-3 mx-5 gap-5'>
            <li>
              <button
                onClick={() => setSelectedPlan('auction')}
                className={`text-[20px] font-semibold ${
                  selectedPlan === 'auction'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('plans.auction')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setSelectedPlan('sale')}
                className={`text-[20px] font-semibold ${
                  selectedPlan === 'sale'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('plans.sale')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setSelectedPlan('rent')}
                className={`text-[20px] font-semibold ${
                  selectedPlan === 'rent'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('plans.rent')}
              </button>
            </li>
          </ul>
          <div className='col-span-3 pt-[68px]'>
            {selectedPlan == 'auction' ? (
              auction != undefined  && <PlanList plans={auction} />
            ): selectedPlan == 'rent' ? (
              rent != undefined && <PlanList plans={rent} />
            ): selectedPlan == 'sale' ? (
              sale != undefined && <PlanList plans={sale} />
            ): ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export { Plans };
