import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminChar } from '../../config';
import { adminServices } from '../../services';
import { Sidenav } from '../Sidenav/Sidenav';
import { SpinnerLoader } from '../Common';

const Characteristics = () => {
  const [charDE, setCharDE] = useState('');
  const [charEN, setCharEN] = useState('');
  const [charFR, setCharFR] = useState('');
  const [charIT, setCharIT] = useState('');
  const [active, setActive] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [input, setInput] = useState({
    char: '',
    active: true,
  });
  const toArr = (name: any) => {
    const newArr = name.split('&');
    return newArr;
  };
  const { t } = useTranslation();
  const [chars, setChars] = useState<Array<AdminChar>>([]);

  const [editedChar, setEditedChar] = useState(0);

  const handleEditMode = (id: any) => {
    setEditedChar(0);
    const active1 = active;
    const stringi = charDE + '&' + charEN + '&' + charFR + '&' + charIT;
    const newChar = {
      ...chars.find((char1) => char1.id === id),
      char: stringi,
      active: active1,
    };
    handleChangeType(newChar);
  };

  const handleCloseEdit = () => {
    setEditedChar(0);
    setCharDE('');
    setCharEN('');
    setCharFR('');
    setCharIT('');
  };

  useEffect(() => {
    setLoading(true);
    adminServices
      .getChars()
      .then((response: any) => {
        setChars(response.data.data);
        console.log(response.data);
        return response.data;
      })
      .catch((error: any) => {
        console.log('Error getting the chars', error);
      }).finally (() => {
        setLoading(false);
      });
  }, []);

  const handleAddChar = () => {
    adminServices
      .postChar(input.char, input.active)
      .then(() => {
        location.reload();
      })
      .catch((err: any) => {
        console.log('Error', err);
      })
      .finally(() => {
        console.log('This is the final step.');
      });
  };

  const handleChangeType = (data: any) => {
    console.log(data);
    adminServices
      .editChars(data)
      .then((resp) => {
        console.log(resp.data);
        setCharDE('');
        setCharEN('');
        setCharFR('');
        setCharIT('');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        location.reload();
      });
  };

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
  };

  useEffect(() => {
    setInput((prev: any) => ({
      ...prev,
      char: charDE + '&' + charEN + '&' + charFR + '&' + charIT
    }));
  }, [charDE, charEN, charFR, charIT]);


  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px] '>
          <div>
            <div className='my-2 pb-4 mr-10 flex justify-end'>
              <button
                className='p-2 rounded-md text-white bg-darkGray'
                onClick={handleOpenAdd}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-5 h-5'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 4.5v15m7.5-7.5h-15'
                  />
                </svg>
              </button>
            </div>
            { loading ? ( <SpinnerLoader /> ) : (
              <div className='flex justify-center overflow-x-auto mr-5'>
                <table className='w-full text-sm text-left text-darkGray mx-5'>
                  <thead className='text-xs text-gray-700  bg-gray-50'>
                    <tr className='text-xl border-b bg-darkGray text-white opacity-80'>
                      <th className='py-3 px-6'>ID</th>
                      <th className='grid grid-cols-4'>
                        <th className='text-left py-3'>German</th>
                        <th className='text-left py-3'>English</th>
                        <th className='text-left py-3'>Français</th>
                        <th className='text-left py-3'>Italiano</th>
                      </th>
                      <th className='py-3 px-6'>Active</th>
                      <th className='py-3 px-6'></th>
                    </tr>
                  </thead>
                  <tbody className='w-full'>
                    {chars?.map((charsRow) => {
                      return (
                        <tr className='py-3 px-6 hover:bg-gray-200' key={charsRow.id}>
                          <td className='py-3 px-6'>{charsRow.id}</td>
                          {charsRow.id === editedChar ? (
                            <>
                              <td className='flex justify-around'>
                                <td className='px-6 py-3'>
                                  <input
                                    type='text'
                                    placeholder={charsRow.char.split('&')[0]}
                                    onChange={(e) => setCharDE(e.target.value)}
                                    className='bg-gray-100'
                                  />
                                </td>
                                <td className='px-6 py-3'>
                                  <input
                                    type='text'
                                    placeholder={charsRow.char.split('&')[1]}
                                    onChange={(e) => setCharEN(e.target.value)}
                                    className='bg-gray-100'
                                  />
                                </td>
                                <td className='px-6 py-3'>
                                  <input
                                    type='text'
                                    placeholder={charsRow.char.split('&')[2]}
                                    onChange={(e) => setCharFR(e.target.value)}
                                    className='bg-gray-100'
                                  />
                                </td>
                                <td className='px-6 py-3'>
                                  <input
                                    type='text'
                                    placeholder={charsRow.char.split('&')[3]}
                                    onChange={(e) => setCharIT(e.target.value)}
                                    className='bg-gray-100'
                                  />
                                </td>
                              </td>
                              <td className='py-3 px-6'>
                                <input
                                  type='checkbox'
                                  checked={active}
                                  onChange={() => setActive(!active)}
                                />
                              </td>
                              <td className='flex justify-start space-x-7 self-start'>
                                <button onClick={handleCloseEdit}>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='1.5'
                                    stroke='currentColor'
                                    className='w-6 h-6'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                    />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => handleEditMode(charsRow.id)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='1.5'
                                    stroke='currentColor'
                                    className='w-6 h-6'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                    />
                                  </svg>
                                </button>
                              </td>
                            </>
                          ) : (
                            <>
                              {/* <td className='py-3 px-6'>{chars.id}</td> */}
                              <td className='grid grid-cols-4'>
                                <div className='text-left py-3'>
                                  <>{toArr(charsRow.char)[0]}</>
                                </div>
                                <div className='text-left py-3'>
                                  <>{toArr(charsRow.char)[1]}</>
                                </div>
                                <div className='text-left py-3'>
                                  <>{toArr(charsRow.char)[2]}</>
                                </div>
                                <div className='text-left py-3'>
                                  <>{toArr(charsRow.char)[3]}</>
                                </div>
                              </td>
                              <td className='py-3 px-6'>
                                {charsRow.active === true ? (
                                  <p>{t('admin.active')}</p>
                                ) : (
                                  <p>{t('admin.inactive')}</p>
                                )}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setEditedChar(charsRow.id);
                                    setCharDE(toArr(charsRow.char)[0]);
                                    setCharEN(toArr(charsRow.char)[1]);
                                    setCharFR(toArr(charsRow.char)[2]);
                                    setCharIT(toArr(charsRow.char)[3]);
                                    setActive(charsRow.active);
                                  }}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='#FF002B'
                                    viewBox='0 0 24 22'
                                    strokeWidth='0.5'
                                    stroke='currentColor'
                                    className='w-6 h-6'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                                    />
                                  </svg>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {/* ADD MODAL FOR CHAR */}
            {openAdd ? (
              <div className='relative z-10'>
                <div className='fixed inset-0 bg-black bg-opacity-90 transition-opacity'></div>
                <div
                  className='fixed inset-0 z-10 overflow-y-auto'
                  onClick={handleOpenAdd}
                >
                  <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                    <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                      <div
                        className='bg-white px-4 pb-4 sm:pb-4'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className=''>
                          <div className=' text-center sm:mt-0 sm:mx-4 sm:text-left'>
                            <div className='my-3 font-bold'>
                              {t('admin.addChar')}
                            </div>
                            <div className=''>
                              <label className='text-sm'>German</label>
                              <input
                                className='appearance-none border-b-2 border-black bg-transparent w-full outline-none focus:border-b-2 focus:border-gray-500 '
                                onChange={(e: any) => setCharDE(e.target.value)}
                              />
                            </div>
                            <div className=''>
                              <label className='text-sm'>English</label>
                              <input
                                className='appearance-none border-b-2 border-black bg-transparent w-full outline-none focus:border-b-2 focus:border-gray-500 '
                                onChange={(e: any) => setCharEN(e.target.value)}
                              />
                            </div>
                            <div className=''>
                              <label className='text-sm'>French</label>
                              <input
                                className='appearance-none border-b-2 border-black bg-transparent w-full outline-none focus:border-b-2 focus:border-gray-500 '
                                onChange={(e: any) => setCharFR(e.target.value)}
                              />
                            </div>
                            <div className=''>
                              <label className='text-sm'>Italian</label>
                              <input
                                className='appearance-none border-b-2 border-black bg-transparent w-full outline-none focus:border-b-2 focus:border-gray-500 '
                                onChange={(e: any) => setCharIT(e.target.value)}
                              />
                            </div>
                            <div className='flex justify-center mt-5'>
                              <button
                                className=' px-6 py-2 rounded-md bg-darkGray flex justify-between text-white '
                                onClick={handleAddChar}
                              >
                                {t('admin.addBtn')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Characteristics };
