import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';


const AllTimeUsers = ({ demographics }:{demographics: any}) => {
  const cityCounts = demographics.report?.reduce((acc:any, cur:any) => {
    const city = cur['ga:city'];
    if (city !== '(not set)') {
      acc[city] = (acc[city] || 0) + parseInt(cur['ga:users'], 10);
    }
    return acc;
  }, {});

  const chartData = cityCounts && Object.keys(cityCounts)?.map(city => ({ name: city, value: cityCounts[city], city: city }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1919', '#796cec', '#4ae1d7', '#f35832', '#de495b', '#66dc13', '#3fdece', '#e2b768', '#5503bc', '#8f1d60',
    '#d59464',
  ];

  return (
    <div style={{ width: '100%', height: '800px', display: 'flex', justifyContent: 'center' }}>
      <PieChart width={800} height={600}>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="city"
          cx="50%"
          cy="50%"
          innerRadius={80}
          width={1000}
          outerRadius={200}
          paddingAngle={2}
          label={({ name, value }) => `${name}: ${value}`}
        >
          {chartData?.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`}  fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export { AllTimeUsers, };
