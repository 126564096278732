import React, { useEffect, useState } from 'react';
import { adminServices } from '../../services';
import { useTranslation } from 'react-i18next';
import { Sidenav } from '../Sidenav/Sidenav';
import { LastDayPayment } from './LastDayPayment';
import { LastWeekPayment } from './LastWeekPayment';
import { SpinnerLoader } from '../Common';

const Payments = () => {
  const { t } = useTranslation();
  const [data24h, setData24h] = React.useState<any>([]);
  const [data7d, setData7d] = React.useState<any>([]);
  const [selectedPayment, setSelectedPayment] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    adminServices.getPayments24h().then((res: any) => {
      setData24h(res.data);
    }).catch((err: any) => {
      console.log(err);
    }).finally (() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // setLoading(true);
    adminServices.getPayments7d().then((res: any) => {
      setData7d(res.data);
    }).catch((err: any) => {
      console.log(err);
    }).finally (() => {
      // setLoading(false);
    });
  }, []);

  const [user, setUser] = React.useState<any>();
  const getUser = (id: any) => {
    adminServices.getUser(id).then((res: any) => {
      setUser(res.data);
      console.log(res.data);
    }).catch((err: any) => {
      console.log(err);
    });
  };



  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <div className='px-2 pt-[48px]'>
            <ul className='block sm:flex sm:justify-around text-[20px]'>
              <li className={selectedPayment === 1 ? 'border-b-2 border-red-600' : ''} >
                <button onClick={() => setSelectedPayment(1)} className='' >{t('admin.24h')}</button>
              </li>
              <li className={selectedPayment === 2 ? 'border-b-2 border-red-600' : ''} >
                <button onClick={() => setSelectedPayment(2)} className='' >{t('admin.7d')}</button>
              </li>
            </ul>
          </div>
          { loading ? ( <SpinnerLoader /> ) : (
            <div className='overflow-x-auto sm:mr-[206px] pt-10'>
              <table className='w-full'>
                <thead className='border-b'>
                  <tr className='font-normal'>
                    <th className='px-14 py-4'>{t('payments.paymentId')}</th>
                    <th className='px-14 py-4'>{t('payments.userId')}</th>
                    <th className='px-14 py-4'>{t('payments.price')}</th>
                    <th className='px-14 py-4'>{t('payments.status')}</th>
                    <th className='px-14 py-4'>{t('payments.moreInfo')}</th>
                  </tr>
                </thead>
                { selectedPayment == 1 ? (
                  <LastDayPayment data24h={data24h} user={user} getUser={getUser} />
                ):('')}
                { selectedPayment == 2 ? (
                  <LastWeekPayment data7d={data7d} user={user} getUser={getUser} />
                ):('')}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Payments };
