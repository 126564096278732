import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidenav } from '../Sidenav/Sidenav';
import { Auction } from './Auction';
import { Rent } from './Rent';
import { Sale } from './Sale';
import { adminServices } from '../../services';
import { SpinnerLoader } from '../Common';

export interface PropsAuction {
  viewPropertyData: any;
  setViewPropertyData: any;
  filteredAuctionProperties: any;
  filterAuction: any;
  handleGetInvoice: any;
  isButtonLoading: any;
  user: any;
  getUser: any;
}

export interface PropsRent {
    viewPropertyData: any;
    setViewPropertyData: any;
    filteredRentProperties: any;
    filterRent: any;
    handleGetInvoice: any;
    isButtonLoading: any;
    user: any;
    getUser: any;
}
export interface PropsSale {
    viewPropertyData: any;
    setViewPropertyData: any;
    filteredSaleProperties: any;
    filterSale: any;
    handleGetInvoice: any;
    isButtonLoading: any;
    user: any;
    getUser: any;
}

const Properties = () => {
  const [propertyType, setPropertyType] = useState('auction');
  const [viewPropertyData, setViewPropertyData] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [auctionProperties, setAuctionProperties] = React.useState<any>([]);
  const [rentProperties, setRentProperties] = React.useState<any>([]);
  const [saleProperties, setSaleProperties] = React.useState<any>([]);
  const [keyword, setKeyword] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [filteredAuctionProperties, setFilteredAuctionProperties] = useState(auctionProperties);
  const [filteredRentProperties, setFilteredRentProperties] = useState(rentProperties);
  const [filteredSaleProperties, setFilteredSaleProperties] = useState(saleProperties);

  const { t } = useTranslation();

  const filterAuction = (auctionProperties: any) => {
    return auctionProperties?.filter((prop: any) => prop?.title?.toLowerCase().includes(keyword.toLowerCase()));
  };
  
  const filterRent = (rentProperties: any) => {
    return rentProperties?.filter((prop: any) => prop?.title?.toLowerCase().includes(keyword.toLowerCase()));
  };
  
  const filterSale = (saleProperties: any) => {
    return saleProperties?.filter((prop: any) => prop?.title?.toLowerCase().includes(keyword.toLowerCase()));
  };
  const [user, setUser] = React.useState<any>();
  const getUser = (id: any) => {
    adminServices.getUser(id).then((res: any) => {
      setUser(res.data);
      console.log(res.data);
    }).catch((err: any) => {
      console.log(err);
    });
  };

  if (propertyType === 'auction') {
    useEffect(() => {
      setLoading(true);
      adminServices
        .getAuctionProperties()
        .then((response: any) => {
          setAuctionProperties(response.data);
          setFilteredAuctionProperties(response.data);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(t('error.errorGettingTheChars'), error);
        }).finally(() => {
          setLoading(false);
        });
    }, [propertyType]);
  } else if (propertyType === 'rent') {
    useEffect(() => {
      setLoading(true);
      adminServices
        .getRentProperties()
        .then((response: any) => {
          setRentProperties(response.data);
          setFilteredRentProperties(response.data);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(t('error.errorGettingTheChars'), error);
        }).finally(() => {
          setLoading(false);
        });
    }, [propertyType]);
  }
  else if (propertyType === 'sale') {
    useEffect(() => {
      setLoading(true);
      adminServices
        .getSaleProperties()
        .then((response: any) => {
          setSaleProperties(response.data);
          setFilteredSaleProperties(response.data);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(t('error.errorGettingTheChars'), error);
        }).finally(() => {
          setLoading(false);
        });
    }, [propertyType]);
  }

  const handleGetInvoice = (property_id : number) => {
    const newTab = window.open();
    setIsButtonLoading(true);
    adminServices
      .getPropertyInvoice(property_id)
      .then((response: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newTab.location.href = response?.data?.link;
        setIsButtonLoading(false);
      })
      .catch((error: any) => {
        console.log('Error getting the chars', error);
        setIsButtonLoading(false);
      }).finally(() => {
        setIsButtonLoading(false);
      });
  };


  useEffect(() => {
    if ( keyword !== '') {
      setFilteredAuctionProperties(filterAuction(auctionProperties));
      setFilteredRentProperties(filterRent(rentProperties));
      setFilteredSaleProperties(filterSale(saleProperties));
    }
    else { setFilteredAuctionProperties(auctionProperties);
      setFilteredRentProperties(rentProperties);
      setFilteredSaleProperties(saleProperties);}
  }, [keyword,]);

  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <ul className='grid grid-cols-3 sm:px-12 pb-12 text-[20px]  '>
            <li>
              <button
                onClick={() => setPropertyType('auction')}
                className={`text-[20px] font-semibold ${
                  propertyType === 'auction'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('properties.auction')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setPropertyType('rent')}
                className={`text-[20px] font-semibold ${
                  propertyType === 'rent'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('properties.rent')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setPropertyType('sale')}
                className={`text-[20px] font-semibold ${
                  propertyType === 'sale'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('properties.sale')}
              </button>
            </li>
          </ul>
          <div className=''>
            <div className='grid grid-cols-5 px-2 sm:px-1'>
              <div className='col-span-2 sm:col-span-3'>
                <div className='flex '>
                  <input
                    type='search'
                    className='border border-[#ABABAC] rounded-md px-3 w-full h-[48px] '
                    // placeholder={t('properties.searchProperties')}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              </div>
              <div className='flex col-span-3 sm:col-span-2 sm:pt-0'>
                <div className='pl-[24px] '>
                  <button className='px-3 py-3 bg-darkGray rounded-md'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='white'
                      strokeWidth='2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M17 8l4 4m0 0l-4 4m4-4H3'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {loading ? ( <SpinnerLoader /> ) : (
            <div className='grid grid-cols-6 '>
              <div className='col-span-full sm:col-span-5 overflow-x-auto mt-[36px]'>
                <table className='text-[14px] w-full'>
                  <thead className='border-b border-gray-200 '>
                    <th className='px-4 py-4'>{t('properties.preview')}</th>
                    <th className='px-4 py-4'>{t('properties.propertyName')}</th>
                    <th className='px-4 py-4' colSpan={2}>{t('properties.address')}</th>
                    <th className='px-4 py-4'>{t('properties.status')}</th>
                    <th className='px-4 py-4'>{t('properties.moreInfo')}</th>
                  </thead>
                  {propertyType === 'auction' ? (
                    <Auction filterAuction={filterAuction} filteredAuctionProperties={filteredAuctionProperties} isButtonLoading={isButtonLoading}
                      viewPropertyData={viewPropertyData} setViewPropertyData={setViewPropertyData} handleGetInvoice={handleGetInvoice} user={user} getUser={getUser} />
                  ) : (propertyType === 'rent' ? (
                    <Rent filterRent={filterRent} filteredRentProperties={filteredRentProperties} isButtonLoading={isButtonLoading}
                      viewPropertyData={viewPropertyData} setViewPropertyData={setViewPropertyData} handleGetInvoice={handleGetInvoice} user={user} getUser={getUser}
                    /> ) : ( propertyType === 'sale' ? (
                    <Sale filterSale={filterSale} filteredSaleProperties={filteredSaleProperties} isButtonLoading={isButtonLoading}
                      viewPropertyData={viewPropertyData} setViewPropertyData={setViewPropertyData} handleGetInvoice={handleGetInvoice} user={user} getUser={getUser}
                    /> ) : '' ) ) }
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Properties };
