import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Interest } from '../../config';
import { adminServices } from '../../services';
import { Sidenav } from '../Sidenav/Sidenav';
import { SpinnerLoader } from '../Common';
import { toast } from 'react-toastify';

const InterestRate = () => {
  const [interestRate, setInterestRate] = useState<Array<Interest>>([]);
  const [editRate, setEditRate] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [updateModal, setUpdateModal] = React.useState(false);
  const [updateLloading, setUpdateLoading] = React.useState(false);

  const handleEditMode = () => {
    setEditRate(!editRate);
  };

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    adminServices
      .getInterest()
      .then((response) => {
        console.log(response.data);
        setInterestRate(response.data);
      })
      .catch((error) => {
        console.log('Error getting the interst rate', error);
      }).finally (() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (index: number, value: number) => {
    const updatedRates = interestRate.map((interest, i) => {
      if (i === index) {
        return { ...interest, rate: value };
      }
      return interest;
    });
    setInterestRate(updatedRates);
  };

  const handleUpdate = () => {
    setUpdateLoading(true);
    adminServices.editInterest(interestRate).then(()=>{
      toast.success('Interest rates updated successfully!');
    }).catch((e:any) => {
      console.log(e);
      toast.error('Error updating interest rates');
    }).finally(()=>{
      setEditRate(false);
      setUpdateLoading(false);
      setUpdateModal(false);
      setLoading(true);
      adminServices
        .getInterest()
        .then((response) => {
          console.log(response.data);
          setInterestRate(response.data);
        })
        .catch((error) => {
          console.log('Error getting the interst rate', error);
        }).finally (() => {
          setLoading(false);
        });
    })
    ;
  };

  console.log(interestRate);
  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        {updateModal && <Modal isOpen={updateModal} onClose={()=>setUpdateModal(false)} onUpdate={handleUpdate} loading={updateLloading}/>}
        <div className='col-span-3 pt-[68px] mx-5 mr-10'>
          { loading ? ( <SpinnerLoader /> ) : (
            <table className='w-full '>
              <thead className='text-xs text-white z-10 bg-darkGray opacity-80 text-left'>
                <tr className='text-xl border-b text-left'>
                  <th className='py-3 px-6'>ID</th>
                  <th className='py-3'>{t('InterestRate.nrOfYears')}</th>
                  <th className='py-3'>{t('InterestRate.rate')}</th>
                  {editRate ? <th className='flex items-center self-center'>
                    <button className='flex self-center mr-5' onClick={handleEditMode}>
                      Exit
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                        />
                      </svg>
                    </button>
                    <button onClick={()=>setUpdateModal(true)} className='flex mr-5'>
                      Save
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                        />
                      </svg>
                    </button></th>:
                    <th>
                      <button onClick={handleEditMode}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='#FF002B'
                          viewBox='0 0 24 22'
                          strokeWidth='0.5'
                          stroke='currentColor'
                          className='w-6 h-6'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M18 13.45V20H2V8H8.743L10.721 6H0V22H20V11.427L18 13.45ZM19.473 2.835L21.18 4.542L11.899 13.92L9.669 14.392L10.181 12.223L19.473 2.835ZM19.465 0L8.361 11.216L7 17L12.898 15.752L24.001 4.534L19.465 0Z'
                          />
                        </svg>
                      </button>
                    </th>}
                </tr>
              </thead>
              <tbody className=''>
                {interestRate?.map((interest, index) => {
                  return (
                    <tr key={interest.id} className='hover:bg-gray-200'>
                      <td className='py-2 font-bold text-sm px-6 '>
                        {interest.id}
                      </td>
                      <td className='py-2 '>{interest.number_of_years} {t('InterestRate.years')}</td>
                      <td>
                        {editRate ? (
                          <div className='flex'>
                            <div>
                              <input
                                type='number'
                                placeholder={`${interest.rate}`}
                                className='bg-gray-50 px-2 rounded-md'
                                value={interestRate!=undefined ? interest.rate : ''}
                                onChange={(e) =>
                                  handleChange(index, parseFloat(e.target.value))
                                }
                              />
                            </div>
                           
                          </div>
                        ) : (
                          <div
                            key={interest.id}
                            className='text-darkGray flex justify-between'
                          >
                            <div className='py-2 font-bold text-sm px-6'>
                              {interest.rate}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};


const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  loading: any;
}> = ({ isOpen, onClose, onUpdate, loading }) => {
  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={handleClickOutside}
          ></div>
          <div className="relative bg-white z-50 p-6 rounded-md text-center">
            <h2 className="text-xl font-bold mb-4">Update Interest Rate</h2>
            <p className="mb-4">Do you want to update the interest rate?</p>
            <div className="flex justify-center">
              <button
                className="bg-auctionicRed hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={onUpdate}
                disabled={loading}
              >{loading && <SpinnerLoader />}
                Yes
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

export { InterestRate };
