import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminServices } from '../../services';
import { SpinnerLoader } from '../Common';
import { IndividualUser } from './IndividualUser';
import { CompanyUser } from './CompanyUser';
import { Discount } from '../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface Individual {
  users: any;
  t: any;
  isButtonLoading: boolean;
  showDiscount: boolean;
  setShowDiscount: any;
  filteredPost: any;
  filterUsers: any;
  showAddDiscount: boolean;
  setShowAddDiscount: any;
  setKeyword: any;
  filterUsersCompany: any;
  handleGetDiscount: any;
  getDiscount: any;
  setGetDiscount: any;
  viewClientData: any;
  setViewClientData: any;
  discount: any;
  setDiscount: any;
  handleCreateDiscount: any;
  confirmUserEmail: any;
}

export interface Company {
  users: any;
  t: any;
  isButtonLoading: boolean;
  showDiscount: boolean;
  setShowDiscount: any;
  filteredPost: any;
  showAddDiscount: boolean;
  setShowAddDiscount: any;
  setKeyword: any;
  filterUsersCompany: any;
  handleGetDiscount: any;
  getDiscount: any;
  setGetDiscount: any;
  viewClientData: any;
  setViewClientData: any;
  discount: any;
  setDiscount: any;
  handleCreateDiscount: any;
  confirmUserEmail: any;
}

const SearchIndex = () => {
  const { t } = useTranslation();
  const [userType, setUserType] = useState('Individual');
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [keyword, setKeyword,] = useState<string>('');
  const [filteredPost, setFilteredPost,] = useState(users);
  const [showAddDiscount, setShowAddDiscount] = useState(false);
  const [getDiscount, setGetDiscount] = useState<Array<Discount>>([]);
  const [viewClientData, setViewClientData] = useState<Array<string>>([]);

  const handleGetDiscount = (user_id: number) => {
    setIsButtonLoading(true);
    adminServices.getUserDiscount(user_id).then((res) => {
      setIsButtonLoading(false);
      setShowDiscount(true);
      console.log(res);
      setGetDiscount(res.data);
      setShowDiscount(true);
      return res.data;
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setIsButtonLoading(false);
    });
  };

  const confirmUserEmail = (user_id: number) => {
    adminServices.confirmUserEmail(user_id).then(()=>{
      toast.success(('Email confirmed Successfully!'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setTimeout(() => {
        window.location.reload();
      },3000);
    }).catch(()=>{
      toast.error(('Error confirming the email'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const filterUsers = (users: any) => {
    return users?.individual?.filter((user: any) => user.first_name.toLowerCase().includes(keyword.toLowerCase()) || user.email.includes(keyword.toLowerCase()));
  };

  const filterUsersCompany = (users: any) => {
    return users?.company?.filter((user: any) => user.name.toLowerCase().includes(keyword.toLowerCase())  || user.email.includes(keyword.toLowerCase()));
  };

  useEffect(() => {
    setLoading(true);
    adminServices
      .getAllUsers()
      .then((response: any) => {
        setUsers(response.data);
        setFilteredPost(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log('Error getting the chars', error);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const [discount, setDiscount] = useState({
    user_id: viewClientData[0],
    percentage: 0,
    time_limit: '',
    total_discounts: 0,
    available_discounts: 0,
  });

  useEffect(() => {
    setDiscount({ ...discount, user_id: viewClientData[0] });
  }, [viewClientData]);

  const handleCreateDiscount = () => {
    setIsButtonLoading(true);
    adminServices.createDiscount(discount).then((res) => {
      toast.success(('Successfully created discount'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log(res);
      setIsButtonLoading(false);
      return res.data;
    }
    ).catch((err) => {
      toast.error(('Error creating discount'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log(err);
      setIsButtonLoading(false);
    }
    ).finally(() => {
      setIsButtonLoading(false);
    });
  };


  useEffect(() => {
    if ( keyword !== '') {
      setFilteredPost(filterUsers(users));
    }
    else { setFilteredPost(users); }
  }, [keyword,]);

  if (loading) {
    return <SpinnerLoader />;
  }


  return (
    <div className='overflow-x-auto px-2 sm:px-0 grid grid-cols-1'>
      <ToastContainer autoClose={4000}/>
      <div className='pt-[43px] grid grid-cols-6 '>
        <div className='flex col-span-full sm:col-span-5 '>
          <input
            type='search'
            className='border border-[#ABABAC] rounded-lg py-4 px-3 w-full'
            placeholder={`${t('admin.search')}`}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </div>
      </div>
      <div className='flex pt-5 space-x-9'>
        <p className='font-semibold text-base'>User type:</p>
        <div className={'flex space-x-5 items-center'}>
          <label htmlFor={'individual'} className='text-base'>Individual</label>
          <input name='type' id='individual' defaultChecked checked={userType == 'Individual'} onClick={() => setUserType('Individual')} type='radio' value='Individual' className='w-4 h-4' />
        </div>
        <div className={'flex space-x-5 items-center'}>
          <label htmlFor='company' className='text-base'>Company</label>
          <input name='type' id='company' type='radio' onClick={() => setUserType('Company')} value='Company' className='w-4 h-4' />
        </div>
      </div>
      <div className='grid grid-cols-6 '>
        <div className='col-span-full sm:col-span-5 border border-[#ABABAC] rounded-lg overflow-x-auto mt-[36px] mb-10'>
          <table className='w-full'>
            <thead className='border-b border-gray-200 '>
              <th className='px-14 py-4'>{t('admin.name')}</th>
              <th className='px-14 py-4'>{t('admin.email')}</th>
              <th className='px-14 py-4'>{t('admin.type')}</th>
              <th className='px-14 py-4'>{t('admin.moreInfo')}</th>
            </thead>
            {userType === 'Individual' ? (
              <IndividualUser users={users}
                handleGetDiscount={handleGetDiscount}
                getDiscount={getDiscount} setGetDiscount={setGetDiscount}
                setKeyword={setKeyword} showAddDiscount={showAddDiscount}
                setShowAddDiscount={setShowAddDiscount} filterUsersCompany={filterUsersCompany}
                filteredPost={filteredPost} filterUsers={filterUsers} t={t} isButtonLoading={isButtonLoading}
                showDiscount={showDiscount}
                setShowDiscount={setShowDiscount} viewClientData={viewClientData} setViewClientData={setViewClientData}
                discount={discount} setDiscount={setDiscount} handleCreateDiscount={handleCreateDiscount} confirmUserEmail={confirmUserEmail}/>
            ) : (
              <CompanyUser users={users}
                t={t} handleGetDiscount={handleGetDiscount}
                getDiscount={getDiscount} setGetDiscount={setGetDiscount}
                setKeyword={setKeyword} filteredPost={filteredPost} showAddDiscount={showAddDiscount}
                setShowAddDiscount={setShowAddDiscount} filterUsersCompany={filterUsersCompany}
                isButtonLoading={isButtonLoading}
                showDiscount={showDiscount} setShowDiscount={setShowDiscount}
                viewClientData={viewClientData} setViewClientData={setViewClientData}
                discount={discount} setDiscount={setDiscount} handleCreateDiscount={handleCreateDiscount} confirmUserEmail={confirmUserEmail}/>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
export { SearchIndex };


