import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sidenav } from '../Sidenav/Sidenav';
// import socketIO from 'socket.io-client';

// const socket = socketIO('http://192.168.1.109:5000/');
// socket.connect();

const AdminDashboard = () => {
  const { t } = useTranslation();
  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px] '>
          <div className='grid grid-cols-1 sm:grid-cols-3 '>
            <div className='w-[230px] col-span-1 self-center ml-10 sm:ml-0'>
              {/* Current users */}
              <div className='bg-gray-100 rounded-lg block '>
                <div className='flex justify-center pt-[16px] pb-[6px] border-b border-[#ABABAC] mx-3 '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    fill='none'
                    viewBox='0 0 18 18'
                  >
                    <path
                      fill='red'
                      d='M14.25 5.25a5.25 5.25 0 1 1-10.501 0 5.25 5.25 0 0 1 10.501 0Zm-1.198 5.386A6.702 6.702 0 0 1 9 12a6.714 6.714 0 0 1-4.055-1.367C1.89 11.98 0 16.166 0 18h18c0-1.817-1.95-6.005-4.948-7.364Z'
                    />
                  </svg>
                  <p className='text-[14px] pl-3 '>{t('admin.currentUsers')}</p>
                </div>
                <div className='text-center pb-[11px] pt-[10px] '>
                  <span className='text-[22px] font-semibold'>833</span>
                </div>
              </div>
              {/* Last properties sold */}
              <div className='mb-[20px] sm:mb-0 mt-10 bg-gray-100 rounded-lg h-[253px] overflow-y-auto '>
                <div className='flex justify-center pt-[16px] pb-[6px] border-b border-[#ABABAC] mx-3 '>
                  <p className='text-[14px]'>{t('admin.lastPropertiesSold')}</p>
                </div>
                <div className='pt-[22px]  '>
                  <div className='flex px-[29px] pb-[14px]'>
                    <div className=''>
                      <img
                        src='https://blurealty.com/images/2013/08/27/superb-a-house-mnm-mod-1.jpg'
                        alt=''
                        className='w-[40px] h-[40px]'
                      />
                    </div>
                    <div className='flex justify-between '>
                      <div className='block pl-[14px]'>
                        <p className='text-[11px]'>451 Bruns St.</p>
                        <p className='text-[9px]'>CHF 12,500</p>
                      </div>
                      <div className='pl-[35px]'>
                        <button className='bg-darkGray rounded-md px-2 py-2'>
                          <svg
                            width='14'
                            height='11'
                            viewBox='0 0 14 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M9.26122 0.218757L13.7915 4.97188C14.0695 5.26355 14.0695 5.73645 13.7915 6.02812L9.26122 10.7812C8.98322 11.0729 8.53249 11.0729 8.25449 10.7812C7.97649 10.4896 7.97649 10.0167 8.25449 9.72499L11.5695 6.24688L-5.4612e-07 6.24688L-4.15531e-07 4.75312L11.5695 4.75312L8.25449 1.27501C7.97649 0.983331 7.97649 0.510432 8.25449 0.218757C8.5325 -0.072918 8.98322 -0.072918 9.26122 0.218757Z'
                              fill='white'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='flex px-[29px] pb-[14px]'>
                    <div className=''>
                      <img
                        src='https://blurealty.com/images/2013/08/27/superb-a-house-mnm-mod-1.jpg'
                        alt=''
                        className='w-[40px] h-[40px]'
                      />
                    </div>
                    <div className='flex justify-between '>
                      <div className='block pl-[14px]'>
                        <p className='text-[11px]'>451 Bruns St.</p>
                        <p className='text-[9px]'>CHF 12,500</p>
                      </div>
                      <div className='pl-[35px]'>
                        <button className='bg-darkGray rounded-md px-2 py-2'>
                          <svg
                            width='14'
                            height='11'
                            viewBox='0 0 14 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M9.26122 0.218757L13.7915 4.97188C14.0695 5.26355 14.0695 5.73645 13.7915 6.02812L9.26122 10.7812C8.98322 11.0729 8.53249 11.0729 8.25449 10.7812C7.97649 10.4896 7.97649 10.0167 8.25449 9.72499L11.5695 6.24688L-5.4612e-07 6.24688L-4.15531e-07 4.75312L11.5695 4.75312L8.25449 1.27501C7.97649 0.983331 7.97649 0.510432 8.25449 0.218757C8.5325 -0.072918 8.98322 -0.072918 9.26122 0.218757Z'
                              fill='white'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='flex px-[29px] pb-[14px]'>
                    <div className=''>
                      <img
                        src='https://blurealty.com/images/2013/08/27/superb-a-house-mnm-mod-1.jpg'
                        alt=''
                        className='w-[40px] h-[40px]'
                      />
                    </div>
                    <div className='flex justify-between '>
                      <div className='block pl-[14px]'>
                        <p className='text-[11px]'>451 Bruns St.</p>
                        <p className='text-[9px]'>CHF 12,500</p>
                      </div>
                      <div className='pl-[35px]'>
                        <button className='bg-darkGray rounded-md px-2 py-2'>
                          <svg
                            width='14'
                            height='11'
                            viewBox='0 0 14 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M9.26122 0.218757L13.7915 4.97188C14.0695 5.26355 14.0695 5.73645 13.7915 6.02812L9.26122 10.7812C8.98322 11.0729 8.53249 11.0729 8.25449 10.7812C7.97649 10.4896 7.97649 10.0167 8.25449 9.72499L11.5695 6.24688L-5.4612e-07 6.24688L-4.15531e-07 4.75312L11.5695 4.75312L8.25449 1.27501C7.97649 0.983331 7.97649 0.510432 8.25449 0.218757C8.5325 -0.072918 8.98322 -0.072918 9.26122 0.218757Z'
                              fill='white'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='flex px-[29px] pb-[14px]'>
                    <div className=''>
                      <img
                        src='https://blurealty.com/images/2013/08/27/superb-a-house-mnm-mod-1.jpg'
                        alt=''
                        className='w-[40px] h-[40px]'
                      />
                    </div>
                    <div className='flex justify-between '>
                      <div className='block pl-[14px]'>
                        <p className='text-[11px]'>451 Bruns St.</p>
                        <p className='text-[9px]'>CHF 12,500</p>
                      </div>
                      <div className='pl-[35px]'>
                        <button className='bg-darkGray rounded-md px-2 py-2'>
                          <svg
                            width='14'
                            height='11'
                            viewBox='0 0 14 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M9.26122 0.218757L13.7915 4.97188C14.0695 5.26355 14.0695 5.73645 13.7915 6.02812L9.26122 10.7812C8.98322 11.0729 8.53249 11.0729 8.25449 10.7812C7.97649 10.4896 7.97649 10.0167 8.25449 9.72499L11.5695 6.24688L-5.4612e-07 6.24688L-4.15531e-07 4.75312L11.5695 4.75312L8.25449 1.27501C7.97649 0.983331 7.97649 0.510432 8.25449 0.218757C8.5325 -0.072918 8.98322 -0.072918 9.26122 0.218757Z'
                              fill='white'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-2 mx-2 sm:mr-20'>
              {/* User traffic */}
              <div className='bg-gray-100 rounded-lg '>
                <div className='flex justify-center pt-[16px] pb-[6px] border-b border-[#ABABAC] mx-10 '>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    fill='none'
                    viewBox='0 0 18 18'
                  >
                    <path
                      fill='red'
                      d='M14.25 5.25a5.25 5.25 0 1 1-10.501 0 5.25 5.25 0 0 1 10.501 0Zm-1.198 5.386A6.702 6.702 0 0 1 9 12a6.714 6.714 0 0 1-4.055-1.367C1.89 11.98 0 16.166 0 18h18c0-1.817-1.95-6.005-4.948-7.364Z'
                    />
                  </svg>
                  <p className='text-[14px] pl-3 '>{t('admin.userTraffic')}</p>
                </div>
                <div className='flex justify-around pt-3'>
                  <div className='block text-center pb-[11px]'>
                    <p className='text-[22px] font-semibold'>89</p>
                    <p className='text-[12px] text-darkGray'>
                      {t('admin.last24h')}
                    </p>
                    <div className='bg-white rounded-lg shadow-lg text-red-600 text-sm p-3 font-semibold mt-[13px]'>
                      <p className=''>-9</p>
                    </div>
                  </div>
                  <div className='block text-center pb-[11px]'>
                    <p className='text-[22px] font-semibold'>616</p>
                    <p className='text-[12px] text-darkGray'>
                      {t('admin.last7d')}
                    </p>
                    <div className='bg-white rounded-lg shadow-lg text-green-600 text-sm p-3 font-semibold mt-[13px]'>
                      <p className=''>+62</p>
                    </div>
                  </div>
                  <div className='block text-center pb-[11px]'>
                    <p className='text-[22px] font-semibold'>2430</p>
                    <p className='text-[12px] text-darkGray'>
                      {t('admin.last30d')}
                    </p>
                    <div className='bg-white rounded-lg shadow-lg text-green-600 text-sm p-3 font-semibold mt-[13px]'>
                      <p className=''>+352</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='block sm:flex justify-between pt-[43px]'>
                <div className='border border-black rounded-lg'>
                  <div className='flex justify-center pt-[16px] pb-[6px] border-b border-[#ABABAC] mx-10 '>
                    <p className='text-[16.8px] px-5'>
                      {t('admin.confirmations')}
                    </p>
                  </div>
                  <div className='flex justify-center pt-[15px]'>
                    <div className=''>
                      <p className='text-[15px]'>
                        <span className='text-red-600 flex justify-center'>
                          11 {t('admin.ads')} <br />
                        </span>
                        {t('admin.areWaiting')}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-center mt-[17px] mb-[9px]'>
                    <button className='bg-darkGray text-sm text-white rounded-md px-5 py-1'>
                      {t('admin.inspect')}
                    </button>
                  </div>
                </div>
                <div className='border border-black rounded-lg mt-5 sm:mt-0'>
                  <div className='flex justify-center pt-[16px] pb-[6px] border-b border-[#ABABAC] mx-10 '>
                    <p className='text-[16.8px] px-5'>{t('admin.revenue')}</p>
                  </div>
                  <div className='flex justify-center pt-[15px]'>
                    <div className=''>
                      <p className='text-xs text-gray-400'>
                        {t('admin.past24h')}
                      </p>
                    </div>
                  </div>
                  <div className='py-[19px] font-[18px] flex justify-center '>
                    <span className='border-b border-[#11D900]'>
                      CHF 21,455
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AdminDashboard };
