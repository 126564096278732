import React, { useState } from 'react';
import './AdminLogin.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { adminServices } from '../../services';
import { Admin } from '../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auctionic from '../../images/logoRed.svg';


const  AdminLogin = () => {
  const [admin, setAdmin] = useState<Admin>({} as Admin);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [input, setInput] = useState({
    email: '',
    password: '',
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };
  console.log(admin);

  const [error, setError] = useState(false);


  const handleLoginWithEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleLoginButton();
    }
  };

  const handleLoginButton = () => {
    adminServices
      .adminLogin(input.email, input.password)
      .then((response: any) => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        setAdmin(response.data.user);
        navigate('/admin/users');
      })
      .catch(() => {
        setError(true);
        toast.error(t('errors.loginError'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <div className='loginContainer '>
      <ToastContainer autoClose={4000} />
      <div className=''>
        <div className='flex items-center justify-center pt-[64px] px-4 sm:px-6 lg:px-8'>
          <div className='max-w-3xl w-full space-y-8 bg-white '>
            <div className='grid grid-cols-1 sm:grid-cols-2 divide-y-2 sm:divide-y-0 divide-x-0 sm:divide-x-2'>
              <div className='space-y-8 font-press-start sm:space-y-10 p-12 sm:pl-[47px] sm:pr-[80px] sm:py-[131px]'
                onKeyDown={handleLoginWithEnter}
              >
                <div className='block '>
                  <label className='pr-2 italic'>{t('login.email')}</label>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 absolute mt-2 fill-gray-500 hover:text-gray-900 '
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z' />
                  </svg>
                  <input
                    type='text'
                    placeholder='example@email.com'
                    name='email'
                    value={input.email}
                    onChange={handleChange}
                    className={`italic py-2 px-6 w-full text-sm text-gray-900 bg-transparent ${error ? 'border-0 border-b-2 border-auctionicRed' : 'border-0 border-b-2 border-gray-300' }  appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                  />
                </div>
                <div className='block'>
                  <label className='pr-2 italic '>{t('login.password')}</label>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 absolute mt-2 fill-gray-500 focus:text-gray-900'
                    viewBox='0 0 20 20'
                  >
                    <path d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' />
                  </svg>
                  <input
                    type='password'
                    placeholder={`${t('login.password')}`}
                    name='password'
                    value={input.password}
                    onChange={handleChange}
                    className={`italic py-2 px-6 w-full text-sm text-gray-900 bg-transparent ${error ? 'border-0 border-b-2 border-auctionicRed' : 'border-0 border-b-2 border-gray-300' } appearance-none focus:bg-transparent  focus:outline-none focus:ring-0 focus:border-black peer required`}
                  />
                </div>
                <div className='float-right'>
                  <div className=''>
                    <button
                      className='bg-darkGray hover:bg-neutral-900 px-14 py-2 rounded-md text-white shadow-md'
                      onClick={handleLoginButton}
                    >
                      {t('login.buttonLogin')}
                    </button>
                  </div>
                  <div className='float-right'>
                    <button
                      onClick={() => navigate('/reset')}
                      className='justify-end hover:text-gray-400 font-press-start text-sm transition-all ease-in-out duration-200 '
                    >
                      {t('login.forgetPassword')}
                    </button>
                  </div>
                </div>
              </div>
              <div className='flex justify-center py-10 sm:py-0'>
                <div className='self-center space-y-5 sm:space-y-[30px]'>
                  <div className='text-center space-y-3'>
                    <img src={Auctionic} alt='auctionicLogo'/>
                    <p className='text-auctionicRed font-braga tracking-widest'>ADMIN</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-start pb-[64px] justify-center px-4 sm:px-6 lg:px-8  font-avenir55'>
          <div className='max-w-3xl w-full'>
            <div className=''>
              <button
                onClick={() => navigate('/')}
                className=' bg-white hover:bg-gray-100 px-2 py-2 mt-3 rounded-md'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='black'
                >
                  <path d='M10 19l-7-7m0 0l7-7m-7 7h18' />
                </svg>
              </button>
              <a
                href='/'
                className='text-white absolute ml-2 mt-5 sm:mt-5 hover:underline font-press-start'
              >
                {t('login.backButton')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { AdminLogin };
