import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { adminServices } from '../../services';
import { Sidenav } from '../Sidenav/Sidenav';
import { AllTimeUsers } from './AllTimeUsers';
import { LastDayUsers } from './LastDayUsers';
import { LastWeekUsers } from './LastWeekUsers';
import { LastMonthUsers } from './LastMonthUsers';
import { SpinnerLoader } from '../Common';


const UsersTraffic = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>([]);
  const [demographics, setDemographics] = React.useState<any>([]);
  const [selectedChart, setSelectedChart] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  
  useEffect(() => {
    setLoading(true);
    adminServices.getAnalytics().then((res: any) => {
      setData(res.data);
    }).catch((err: any) => {
      console.log(err);
    }).finally (() => {
      setLoading(false);
    });
  }, []);

  console.log(data.report, 'data.report');

  // Last day users
  const lastDayUsers = data.report?.pop();
  const lastDayTotalUsers = lastDayUsers && lastDayUsers['ga:1dayUsers'];

  // Last week users

  const lastWeekUsers = data.report?.slice(-7);
  const lastWeekTotalUsers = lastWeekUsers?.reduce((acc: any, obj: any) => acc + parseInt(obj['ga:1dayUsers']), 0);

  // Last month users
  const lastMonthUsers = data.report?.slice(-30);
  const lastMonthTotalUsers = lastMonthUsers?.reduce((acc: any, obj: any) => acc + parseInt(obj['ga:1dayUsers']), 0);


  useEffect(() => {
    adminServices.getDemographics().then((res: any) => {
      console.log(res.data);
      setDemographics(res.data);
    }).catch((err: any) => {
      console.log(err);
    });
  }, []);


  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <div className='block sm:grid sm:grid-cols-3 pb-[43px] px-10'>
            <div className='flex text-lg font-semibold'>
              <p>{t('admin.last24h')}:</p>
              <span className='text-red-600 pl-[29px]'>
                {lastDayTotalUsers}
              </span>
            </div>
            <div className='flex text-lg font-semibold'>
              <p>{t('admin.last7d')}:</p>
              <span className='text-red-600 pl-[29px]'>
                {lastWeekTotalUsers}
              </span>
            </div>
            <div className='flex text-lg font-semibold'>
              <p>{t('admin.last30d')}:</p>
              <span className='text-red-600 pl-[29px]'>
                {lastMonthTotalUsers}
              </span>
            </div>
          </div>
          <hr className='border-[#ABABAC] mx-5' />
          {/* User graph */}
          <div className='pt-[49px]'>
            {/* Graph */}
            <div className='px-2 pt-[48px]'>
              <ul className='block sm:flex sm:justify-around text-[20px]'>
                <li className={selectedChart === 1 ? 'border-b-2 border-red-600' : ''} >
                  <button onClick={() => setSelectedChart(1)} className=''>{t('admin.24h')}</button>
                </li>
                <li className={selectedChart === 2 ? 'border-b-2 border-red-600' : ''}>
                  <button onClick={() => setSelectedChart(2)} className=''>{t('admin.7d')}</button>
                </li>
                <li className={selectedChart === 3 ? 'border-b-2 border-red-600' : ''}>
                  <button onClick={() => setSelectedChart(3)} className=''>{t('admin.30d')}</button>
                </li>
                <li className={selectedChart === 4 ? 'border-b-2 border-red-600' : ''}>
                  <button onClick={() => setSelectedChart(4)} className=''>All time</button>
                </li>
              </ul>
            </div>
            { loading ? ( <SpinnerLoader /> ) : (
              <>
                { selectedChart == 1 ? (
                  <LastDayUsers demographics={demographics} />
                ):('')}
                { selectedChart == 2 ? (
                  <LastWeekUsers demographics={demographics} />
                ):('')
                }
                { selectedChart == 3 ? (
                  <LastMonthUsers demographics={demographics} />
                ):('')
                }
                { selectedChart == 4 ? (
                  <AllTimeUsers demographics={demographics} />
                ):('')
                }
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { UsersTraffic };
