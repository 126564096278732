import React from 'react';
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import './App.css';
import { Landing } from './components';
import { AdminDashboard } from './components/AdminDashboard';
import { Payments } from './components/Payments';
import { Revenue } from './components/AdminDashboard/Revenue';
import { Users } from './components/AdminDashboard/Users';
import { AdminLogin } from './components/AdminLogin';
import { Characteristics } from './components/Characteristics/Characteristics';
import { InterestRate } from './components/InterestRate';
import { Navbar } from './components/Navbar/Navbar';
import { Types } from './components/Types';
import { PostConfirmation } from './components';
import { Errors } from '../src/components/Errors';
import { DebtRequest } from './components/DebtRequest/DebtRequest';
import { Admins } from './components/AdminDashboard/Admins';
import { Properties } from './components/Properties/Properties';
import { Agreements } from './components/Agreements';
import { UsersTraffic } from './components/UserTraffic';
import { Plans } from './components/Plans/Plans';

const HeaderLayout = () => (
  <>
    <Navbar />
    <Outlet />
  </>
);
function App() {
  return (
    <Router>
      <Routes>
        <Route element={<HeaderLayout />}>
          <Route path='/admin/dashboard' element={<AdminDashboard />} />
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/users' element={<Users />} />
          <Route path='/admin/revenue' element={<Revenue />} />
          <Route path='/admin/properties' element={<Properties />} />
          <Route path='/admin/payments' element={<Payments />} />
          <Route path='/admin/types' element={<Types />} />
          <Route path='/admin/chars' element={<Characteristics />} />
          <Route path='/admin/post/confirmation' element={<PostConfirmation />} />
          <Route path='/admin/interest-rate' element={<InterestRate />} />
          <Route path='/admin/errors' element={<Errors />} />
          <Route path='/admin/debt/request' element={<DebtRequest />} />
          <Route path='/admin/list' element={<Admins />} />
          <Route path='/admin/agreements' element={<Agreements />} />
          <Route path='/admin/users/traffic' element={<UsersTraffic />} />
          <Route path='/admin/plans' element={<Plans />} />
        </Route>
        <Route path='/' element={<Landing />} />
      </Routes>
    </Router>
  );
}
export default App;
