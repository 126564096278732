import React from 'react';
import { useTranslation } from 'react-i18next';
import house from '../../images/houser1.png';

const Comission = () => {
  const { t } = useTranslation();
  return (
    <div className='pt-[36px px-5'>
      <div className='mb-[28px] mt-[37px] text-darkGray'>
        <div className='grid grid-cols-1 divide-gray-400 sm:grid-cols-3'>
          <div className=''>
            <div className='block w-full sm:w-[182px]'>
              <img
                src={house}
                alt=''
                className='w-full h-[60%] sm:w-[182px] sm:h-[93px] rounded-md'
              />
              <p className='text-[18px] flex justify-center pt-[7px]'>
                451 Bruns St.
              </p>
            </div>
          </div>
          <div className='block'>
            <div className='flex'>
              <p className='text-gray-600 text-[14px]'>{t('admin.postedBy')}</p>
              <p className='text-[14px] pl-[15px]'>John Doe</p>
            </div>
            <div className='flex pt-[12px]'>
              <p className='text-gray-600 text-[14px]'>{t('admin.boughtBy')}</p>
              <p className='text-[14px] pl-[15px]'>John Smith</p>
            </div>
          </div>
          <div className='block pt-2 sm:pt-0'>
            <div className='flex'>
              <p className='text-gray-600 text-[14px]'>{t('admin.price')}</p>
              <p className='text-[14px] text-red-600 pl-[16px]'>CHF 1,390.-</p>
            </div>
            <div className='flex pt-[12px]'>
              <p className='text-gray-600 text-[#35353580] text-[14px]'>
                5% {t('admin.tax')}
              </p>
              <p className='text-[14px] text-red-600 pl-[16px]'>CHF 69.5.-</p>
            </div>
          </div>
        </div>
        <div className='flex justify-end pb-[26px]'>
          <button className='text-[14px]'>{t('admin.moreInformation')}</button>
        </div>
        <hr className='border-gray-400' />
      </div>
      <div className='mb-[28px] mt-[37px] text-darkGray'>
        <div className='grid grid-cols-1 divide-gray-400 sm:grid-cols-3'>
          <div className=''>
            <div className='block w-full sm:w-[182px]'>
              <img
                src={house}
                alt=''
                className='w-full h-[60%] sm:w-[182px] sm:h-[93px] rounded-md'
              />
              <p className='text-[18px] flex justify-center pt-[7px]'>
                451 Bruns St.
              </p>
            </div>
          </div>
          <div className='block'>
            <div className='flex'>
              <p className='text-gray-600 text-[14px]'>{t('admin.postedBy')}</p>
              <p className='text-[14px] pl-[15px]'>John Doe</p>
            </div>
            <div className='flex pt-[12px]'>
              <p className='text-gray-600 text-[14px]'>{t('admin.boughtBy')}</p>
              <p className='text-[14px] pl-[15px]'>John Smith</p>
            </div>
          </div>
          <div className='block pt-2 sm:pt-0'>
            <div className='flex'>
              <p className='text-gray-600 text-[14px]'>{t('admin.price')}</p>
              <p className='text-[14px] text-red-600 pl-[16px]'>CHF 1,390.-</p>
            </div>
            <div className='flex pt-[12px]'>
              <p className='text-gray-600 text-[#35353580] text-[14px]'>
                5% {t('admin.tax')}
              </p>
              <p className='text-[14px] text-red-600 pl-[16px]'>CHF 69.5.-</p>
            </div>
          </div>
        </div>
        <div className='flex justify-end pb-[26px]'>
          <button className='text-[14px]'>{t('admin.moreInformation')}</button>
        </div>
        <hr className='border-gray-400' />
      </div>
    </div>
  );
};

export { Comission };
