import React from 'react';
import x from '../../images/x.svg';
import check from '../../images/check.svg';
import { Individual } from './SearchIndex';
import { adminServices } from '../../services';
import { toast } from 'react-toastify';

const IndividualUser = (props: Individual) => {
  const { users,
    t,
    isButtonLoading,
    showDiscount,
    setShowDiscount,
    showAddDiscount,
    setShowAddDiscount,
    filterUsers, handleGetDiscount, getDiscount, setGetDiscount, viewClientData, setViewClientData,
    discount, handleCreateDiscount, setDiscount, confirmUserEmail } = props;
  
  const [identifyModal, setIdentifyModal] = React.useState(0);
  const [confirmEmailModal, setConfirmEmailModal] = React.useState(0);

  const confirmIdentity = (userId:number) => {
    adminServices.verifyIdentityIndividual(userId).then(
      () =>{
        toast.success(('User is identified with Identity'), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          window.location.reload();
        },3000);
      }
    ).catch((e)=>{
      console.log(e);
      toast.error(('User failed to identify'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  return (
    <>
      {users && filterUsers(users)?.map((user: any) => (
        <tbody className='' key={user.id}>
          <>
            <tr className={`text-center hover:bg-gray-200 ${viewClientData.includes(user.id) ? 'bg-red-400 hover:bg-red-400' : 'bg-white'}`} key={user.id}>
              <td className='px-6 py-4'>{user.first_name} {user.last_name}</td>
              <td className='px-6 py-4'>{user.email}</td>
              <td className='px-6 py-4'>Individual</td>
              <td className='px-6 py-4'>
                <button
                  className={`px-1 py-1 text-sm bg-darkGray text-white rounded-md`}
                  onClick={() => {
                    if (viewClientData.includes(user.id)) {
                      setViewClientData(
                        viewClientData.filter(
                          (_id: any) => user.id !== _id
                        )
                      );
                      setShowDiscount(false);
                      setShowAddDiscount(false);
                    } else {
                      setViewClientData([
                        user.id,
                      ]);
                    }
                    setGetDiscount([]);
                    setShowDiscount(false);
                    setShowAddDiscount(false);
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='white'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M14 5l7 7m0 0l-7 7m7-7H3'
                    />
                  </svg>
                </button>
              </td>
            </tr>
            {viewClientData.includes(user.id) && (
              <tr>
                <td colSpan={4} className='col-span-3'>
                  <div className='px-2 sm:px-12 my-[20px] '>
                    <div className='w-full bg-darkGray rounded-md pb-[38px]'>
                      <div className='flex justify-end pt-[15px]'>
                        <button className='pr-4 '
                          onClick={() => {
                            if (viewClientData.includes(user.id)) {
                              setViewClientData(
                                viewClientData.filter(
                                  (_id: any) => user.id !== _id
                                )
                              );
                            }
                            else {
                              setViewClientData([
                                user.id,
                              ]);
                            }
                            setGetDiscount([]);
                            setShowDiscount(false);
                            setShowAddDiscount(false);
                          }}
                        >
                          <img src={x} alt='' className='' />
                        </button>
                      </div>
                      <div className='text-white pt-[24px] px-8 sm:pl-[62px]'>
                        <div className='grid grid-cols-1 sm:grid-cols-2'>
                          <div className='block space-y-[31px]'>
                            <div className='flex'>
                              <p className='text-[14px] text-gray-400 pt-0.5'>
                                {t('admin.name')}
                              </p>
                              <span className='text-[16px] pl-[13px]'>{user.first_name} {user.last_name}</span>
                            </div>
                            <div className='flex'>
                              <p className='text-[14px] text-gray-400 pt-0.5'>
                                {t('admin.email')}
                              </p>
                              <span className='text-[16px] pl-[13px]'>
                                {user.email}
                              </span>
                            </div>
                            <div className='flex'>
                              <p className='text-[14px] text-gray-400 pt-0.5'>
                                {t('admin.phoneNumber')}
                              </p>
                              <span className='text-[16px] pl-[13px]'>
                                {user.phone}
                              </span>
                            </div>
                          </div>
                          <div className='block space-y-[31px] text-left'>
                            <div className='flex'>
                              <p className='text-[14px] text-gray-400 pt-0.5'>
                                Mobil telefon
                              </p>
                              <span className='text-[16px] pl-[13px]'>
                                {user.telephone == null ? 'N/A' : user.telephone}
                              </span>
                            </div>
                            <div className='flex'>
                              <p className='text-[14px] text-gray-400 pt-0.5'>
                                Address
                              </p>
                              <span className='text-[16px] pl-[13px]'>
                                {user?.address?.formatted}
                              </span>
                            </div>
                            <div className='flex'>
                              <p className='text-[14px] text-gray-400 pt-0.5'>
                                Bexio ID
                              </p>
                              <span className='text-[16px] pl-[13px]'>
                                {user.bexio_id == null ? 'N/A' : user.bexio_id}
                              </span>
                            </div>
                            {/* <div className='flex'>*/}
                            {/*  <p className='text-[14px] text-gray-400 pt-0.5'>ID</p>*/}
                            {/*  <span className='text-[16px] pl-[13px]'>#1255125</span>*/}
                            {/* </div>*/}
                            {/* <div className='flex'>*/}
                            {/*  <p className='text-[14px] text-gray-400 pt-0.5'>*/}
                            {/*    {t('admin.plan')}*/}
                            {/*  </p>*/}
                            {/*  <span className='text-[16px] pl-[13px]'>Gold</span>*/}
                            {/* </div>*/}
                          </div>
                        </div>
                      </div>
                      <hr className='border border-[#ABABAC] mx-4 sm:mx-10 my-[21px]' />
                      <div className='block sm:block px-8 sm:pl-[62px] space-y-3 sm:space-y-0'>
                        <p className='text-[14px] text-gray-400'>
                          Auctionic activity
                        </p>
                        <div className='flex justify-start space-x-9'>
                          <div className='flex space-x-[14px] items-center '>
                            <p className='text-[14px] text-white pt-3'>
                              Number of logins: {user?.logins}
                            </p>
                          </div>
                          <div className='flex space-x-[14px] items-center '>
                            <p className='text-[14px] text-white pt-3'>
                              Password changes: {user?.password_changed}
                            </p>
                          </div>
                          {user?.password_changed !== 0 && (
                            <div className='flex space-x-[14px] items-center '>
                              <p className='text-[14px] text-white pt-3'>
                                Last time changed password: {user?.password_changed_date}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='border border-[#ABABAC] mx-4 sm:mx-10 my-[21px]' />
                      <div className='block sm:flex px-8 sm:pl-[62px] space-y-3 sm:space-y-0'>
                        <p className='text-[14px] text-gray-400'>
                          {t('admin.authentication')}
                        </p>
                        <div className='flex space-x-[14px] items-center '>
                          <p className='text-[12px] text-white sm:pl-[26px]'>
                            {t('admin.email')}
                          </p>
                          {user?.email_confirmed == true ?
                            <button className='p-1 rounded-sm bg-[#11D900]'>
                              <img src={check} alt='' className=''/>
                            </button>
                            :
                            <button onClick={() => setConfirmEmailModal(user?.id)}>
                              <p className='text-red-500 font-extrabold'>X</p>
                            </button>
                          }
                        </div>
                        <div className='flex space-x-[14px] items-center'>
                          <p className='text-[12px] text-white sm:pl-[26px]'>
                            {t('admin.phoneNumber')}
                          </p>
                          {user?.phone_confirmed == true ?
                            <button className='p-1 rounded-sm bg-[#11D900]'>
                              <img src={check} alt='' className='' />
                            </button>
                            :
                            <p className='text-red-500 font-extrabold'>X</p>
                          }
                        </div>
                        <div className='flex space-x-[14px] items-center'>
                          <p className='text-[12px] text-white sm:pl-[26px]'>
                            Id card confirmation
                          </p>
                          {user?.id_card_confirmed == true ?
                            <button className='p-1 rounded-sm bg-[#11D900]'>
                              <img src={check} alt='' className='' />
                            </button>
                            :
                            <button onClick={() => { setIdentifyModal(user?.id); }}>
                              <p className='text-red-500 font-extrabold'>X</p>
                            </button>
                          }
                        </div>
                      </div>
                      {identifyModal !== 0 &&
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          {/* Overlay with semi-transparent background */}
                          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
                          
                          <div className="bg-white rounded-lg p-6 relative">
                            <h2 className="text-xl font-bold mb-4">Confirm User Identity</h2>
                            <p className="mb-4">Do you want to confirm the identity of user?</p>
                            <div className="flex justify-end">
                              <button className="mr-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => confirmIdentity(user?.id)}>
                                Yes
                              </button>
                              <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={() => setIdentifyModal(0)}>
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                      {confirmEmailModal !== 0 &&
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          {/* Overlay with semi-transparent background */}
                          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
                          
                          <div className="bg-white rounded-lg p-6 relative">
                            <h2 className="text-xl font-bold mb-4">Confirm User Email</h2>
                            <p className="mb-4">Do you want to confirm the email of user?</p>
                            <div className="flex justify-end">
                              <button className="mr-2 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => confirmUserEmail(user?.id)}>
                                Yes
                              </button>
                              <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={() => setConfirmEmailModal(0)}>
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                      <hr className='border border-[#ABABAC] mx-4 sm:mx-10 my-[21px]' />
                      <div className='block sm:flex items-center sm:justify-between px-8 sm:pl-[62px] space-y-3 sm:space-y-0'>
                        <p className='text-[14px] text-gray-400'>
                          Discount informations:
                        </p>
                        <button className={'bg-auctionicRed px-12 py-2 rounded-md text-white'}
                          disabled={isButtonLoading}
                          onClick={() => {handleGetDiscount(user.id); console.log(user.id, 'Test');}}>
                          {
                            isButtonLoading && (
                              <svg role="status" className="inline w-4 h-4 mr-3 text-white animate-spin"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"/>
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"/>
                              </svg>
                            )
                          }
                          {
                            isButtonLoading ? 'Checking discount data' : 'Check discount details'
                          }
                        </button>
                      </div>
                      <hr className='border border-[#ABABAC] mx-4 sm:mx-10 my-[21px]' />
                      {showDiscount && (
                        <div>
                          {getDiscount.length !== 0 ? (
                            <div>
                              <p className='text-gray-200 text-lg text-center font-semibold pb-3'>Discount details</p>
                              {getDiscount.map((discount: any) => {
                                return (
                                  <div key={discount.id} className='text-gray-400'>
                                    <div className='grid grid-cols-2 px-8 text-left sm:pl-[62px]'>
                                      <p>Total discounts: {discount.total_discounts}</p>
                                      <p>Available discounts: {discount.available_discounts}</p>
                                      <p>Percentage: {discount.percentage == null ? 'N/A' : discount.percentage}</p>
                                      <p>Time limit: {discount.time_limit == null ? 'N/A' : discount.time_limit}</p>
                                    </div>
                                    <hr className='border border-[#ABABAC] mx-4 sm:mx-10 my-[21px]' />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p className='text-center text-red-500 font-bold text-xl'>This user has not any discount!</p>
                          )}
                          <div className={'flex justify-center pt-4'}>
                            <button onClick={() => setShowAddDiscount(true)} className='bg-auctionicRed text-white px-12 py-2 rounded-md'>Create discount for this user</button>
                          </div>
                        </div>
                      )}
                      {showAddDiscount && (
                        <div className='p-4 space-y-4'>
                          <div className='flex justify-around space-x-9'>
                            <input name={'test'}
                              onChange={(e) => setDiscount({ ...discount, percentage: Number(e.target.value) })}
                              placeholder='Percentage' className={`italic py-2 px-6 w-full text-sm text-gray-900 bg-gray-100 border-1  border-gray-300  appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                            />
                            <input name={'test'}
                              onChange={(e) => setDiscount({ ...discount, total_discounts: Number(e.target.value), available_discounts: Number(e.target.value) })}
                              placeholder='Total discounts' className={`italic py-2 px-6 w-full text-sm text-gray-900 bg-gray-100 border-1  border-gray-300  appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                            />
                          </div>
                          <div className='flex justify-center space-x-9'>
                            <input type='datetime-local'
                              onChange={(e) => setDiscount({ ...discount, time_limit: e.target.value })}
                              name={'test'} placeholder='Total discounts' className={`italic py-2 px-6 w-full text-sm text-gray-900 bg-gray-100 border-1  border-gray-300  appearance-none focus:outline-none focus:ring-0 focus:border-black peer required`}
                            />
                          </div>
                          <div className='flex justify-center'>
                            <button disabled={isButtonLoading} onClick={handleCreateDiscount} className='bg-auctionicRed text-white rounded-md px-8 py-2'>
                              {
                                isButtonLoading && (
                                  <svg role="status" className="inline w-4 h-4 mr-3 text-white animate-spin"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"/>
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"/>
                                  </svg>
                                )
                              }
                              {
                                isButtonLoading ? 'Creating discount' : 'Create discount'
                              }
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </>
        </tbody>
      ))}
    </>
  );
};

export { IndividualUser };

