import { auctionicApi } from '../helpers/api';
import { headers } from '../helpers/headers';

const adminLogin = (email: string, password: string) => {
  const body = { email, password };
  const config = { headers: headers.getHeaders() };
  return auctionicApi.post('/admin/login', body, config);
};

// const adminLogin2FA = (code: string) => {
//   const body = { code };
//   const config = { headers: headers.getAuthHeaders() };
//   return auctionicApi.post('admin/login_2fa', body, config);
// };

const getTypes = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/type/all', config);
};

const postType = (name: any, active: boolean) => {
  const body = { name, active };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/admin/type', body, config);
};

const editType = (body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/admin/type', body, config);
};

const getInterest = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/interest', config);
};

const editInterest = (body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/admin/interest', body, config);
};

const getChars = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/char', config);
};

const postChar = (char: any, active: boolean) => {
  const body = { char, active };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/admin/char', body, config);
};
const editChars = (body: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/admin/char', body, config);
};

const getPostConfirms = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/post_confirm', config);
};

const postConfirmCode = (id: number, code: string) => {
  const body = { id, code };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/admin/post_confirm', body, config);
};

const printRequest = (id: number) => {
  const body = { id };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/admin/post_confirm/sent', body, config);
};
const getAllUsers = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/users', config);
};

const getUserDiscount = (user_id: number) => {
  const body = { user_id };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/admin/user/discount', body, config);
};

const createDiscount = (discount: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/admin/discount', discount, config);
};

const getErrors = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/error/logs', config);
};

const getDebts = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/request_debt', config);
};

const getAdmins = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/all', config);
};

const createAdmin = (input: any) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/admin/', input, config);
};

const deleteAdmin = (id: string) => {
  const config = { headers: headers.getAuthHeaders() };
  const body = { id };
  return auctionicApi.post('/admin/delete', body, config);
};

const getAuctionProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/all/properties/auction', config);
};

const getRentProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/all/properties/rent', config);
};

const getSaleProperties = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/all/properties/sale', config);
};

const getPropertyInvoice = (property_id: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/admin/invoice/${property_id}`, config);
};

const getAcceptedAgreements = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/agreements_accepted', config);
};

const getWaitingAgreements = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/agreements_waiting', config);
};

const getDeclindedAgreements = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/agreements_declined', config);
};

const getSuccessfulAgreements = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/agreements_successful', config);
};

const getDiscardedAgreements = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/agreements_discarded', config);
};

const agreementDiscard = (id:string) => {
  const config = { headers: headers.getAuthHeaders() };
  const body = { id };
  return auctionicApi.post('/admin/agreements/discard', body, config);
};

const getAnalytics = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/analytics/users30', config);
};

const getDemographics = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/analytics/demographic', config);
};

const resolveConflict = (id: string) => {
  const config = { headers: headers.getAuthHeaders() };
  const body = { id };
  return auctionicApi.post('/admin/resolve/error', body, config);
};

const getPayments24h = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/payments/24h', config);
};

const getPayments7d = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/admin/payments/7d', config);
};

const getPaymentInvoice = (payment_id: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/admin/invoice/${payment_id}`, config);
};

const getUser = (user_id: number) => {
  const body = { user_id };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.post('/admin/user', body, config);
};

const subscriptionAuction = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/plans/auction', config);
};

const subscriptionRent = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/plans/rent', config);
};

const subscriptionSale = () => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get('/plans/sale', config);
};

const updatePlan = (plan: any) => {
  const body = { ...plan };
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.patch('/admin/update/plan', plan, config );
};

const verifyIdentityIndividual = (userId: number) => {
  const config = { headers:  headers.getAuthHeaders() };
  return auctionicApi.get(`/admin/confirm/identity/individual/${userId}/`, config);
};

const verifyPostCompany = (userId: number) => {
  const config = { headers:  headers.getAuthHeaders() };
  return auctionicApi.get(`/admin/confirm/post/company/${userId}/`, config);
};

const confirmUserEmail = (userId: number) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/admin/confirm/user/email/${userId}/`, config);
};

const createComparisEntity = (propId: number, type: string) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/comparis/create_entity/${propId}/${type}`, config);
};

const deleteComparisEntity = (propId: number, type: string) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/comparis/delete_entity/${propId}/${type}`, config);
};

const updateComparisEntity = (propId: number, type: string) => {
  const config = { headers: headers.getAuthHeaders() };
  return auctionicApi.get(`/comparis/update_entity/${propId}/${type}`, config);
};

export const adminServices = {
  adminLogin,
  getTypes,
  postType,
  editType,
  getInterest,
  getChars,
  editChars,
  postChar,
  getPostConfirms,
  postConfirmCode,
  printRequest,
  getAllUsers,
  getUserDiscount,
  createDiscount,
  getErrors,
  getDebts,
  getAdmins,
  createAdmin,
  deleteAdmin,
  getAuctionProperties,
  getRentProperties,
  getSaleProperties,
  getPropertyInvoice,
  getWaitingAgreements,
  getDeclindedAgreements,
  getSuccessfulAgreements,
  getDiscardedAgreements,
  getAcceptedAgreements,
  agreementDiscard,
  getAnalytics,
  getDemographics,
  resolveConflict,
  getPayments24h,
  getPayments7d,
  getPaymentInvoice,
  getUser,
  subscriptionAuction,
  subscriptionRent,
  subscriptionSale,
  updatePlan,
  verifyIdentityIndividual,
  verifyPostCompany,
  editInterest,
  confirmUserEmail,
  createComparisEntity,
  deleteComparisEntity,
  updateComparisEntity,
};
