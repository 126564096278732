import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userServices } from '../../services';

const Sidenav = () => {
  const [admin, setAdmin] = useState(location.pathname);

  const [user, setUser] = useState({
    super_admin: false,
  });

  useEffect(() => {
    userServices
      .userAuth()
      .then((response: any) => {
        console.log(response.data.data);
        setUser(response.data);
        return response.data;
      })
      .catch((error: any) => {
        console.log('Error getting the types', error);
      });
  }, []);
  
  const handleChangeComponents = (event: any) => {
    setAdmin(event);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ul className='space-y-5 text-[18px] '>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/users')}
          className={`flex space-x-2 ${
            admin === '/admin/users'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('admin.users')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            fill='none'
            viewBox='0 0 18 18'
          >
            <path
              fill={admin === '/admin/users' ? '#FF002B' : '#BDBDBD'}
              d='M14.25 5.25a5.25 5.25 0 1 1-10.501 0 5.25 5.25 0 0 1 10.501 0Zm-1.198 5.386A6.702 6.702 0 0 1 9 12a6.714 6.714 0 0 1-4.055-1.367C1.89 11.98 0 16.166 0 18h18c0-1.817-1.95-6.005-4.948-7.364Z'
            />
          </svg>
        </button>
      </li>
      {/* <li className='flex justify-end'>*/}
      {/*  <button*/}
      {/*    onClick={() => navigate('/admin/revenue')}*/}
      {/*    className={`flex space-x-2 ${*/}
      {/*      admin === '/admin/revenue' ? 'text-auctionicRed border-b-2 border-b-darkGray font-semibold' : 'text-gray-400'*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <p>{t('admin.revenue')}</p>*/}
      {/*    <svg*/}
      {/*      xmlns='http://www.w3.org/2000/svg'*/}
      {/*      width='30'*/}
      {/*      height='23'*/}
      {/*      fill='none'*/}
      {/*      viewBox='0 0 30 23'*/}
      {/*    >*/}
      {/*      <path*/}
      {/*        fill={admin === '/admin/revenue' ? '#FF002B' : '#BDBDBD'}*/}
      {/*        d='M15.205 5.206c-1.438.239-2.127 1.541-1.539 2.91.623 1.444 2.401 2.369 3.868 2.004 1.299-.321 1.899-1.565 1.336-2.869-.589-1.369-2.23-2.284-3.665-2.045Zm1.855 3.748.13.286-.274.056-.121-.273a2.128 2.128 0 0 1-.899-.034l-.081-.484c.244.037.548.072.779.025l.156-.051c.276-.136.19-.484-.22-.566-.306-.068-1.116-.018-1.419-.69-.17-.38-.043-.777.445-.958l-.135-.299.272-.056.13.286c.198-.032.43-.045.703-.021l.109.479c-.213-.026-.441-.051-.64-.01l-.075.02c-.386.102-.262.469.08.557.566.131 1.242.174 1.51.765.216.481-.035.81-.45.968Zm12.89 1.321L25.243 0C17.52 5.005 8.982-.398 2.802 5.567L7.51 15.843c6.623-5.615 15.649-.297 22.44-5.567ZM26.446 7.96a6.676 6.676 0 0 0-1.024 2.176c-4.49 1.368-9.863-.251-15.022 1.552-.834-.443-1.879-.72-2.736-.696L6.245 7.884c.54-.657.965-1.657 1.147-2.618 4.25-1.532 9.566.194 15.248-1.751a6.275 6.275 0 0 0 2.162.901l1.644 3.544ZM30 15.012c-8.571 4.927-15.499-1.78-24.375 7.488L0 10.037 1.752 8.21l4.76 10.507-.003.009c9.306-6.994 13.994-1.47 22.636-5.704.367.81.706 1.665.855 1.991Z'*/}
      {/*      />*/}
      {/*    </svg>*/}
      {/*  </button>*/}
      {/* </li>*/}
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/users/traffic')}
          className={`flex space-x-2 ${
            admin === '/admin/users/traffic' ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray' : 'text-gray-400'
          }`}
        >
          <p>Traffic</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='21'
            fill='none'
            viewBox='0 0 22 21'
          >
            <path
              fill={admin === '/admin/users/traffic' ? '#FF002B' : '#BDBDBD'}
              d='m18.333 5.585-2.75-2.75V.917h2.75v4.668ZM22 11h-2.75v9.167H2.75V11H0L11 0l11 11Zm-9.167 1.833H9.167v5.5h3.666v-5.5Z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/properties')}
          className={`flex space-x-2 ${
            admin === '/admin/properties'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('admin.properties')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='21'
            fill='none'
            viewBox='0 0 22 21'
          >
            <path
              fill={admin === '/admin/properties' ? '#FF002B' : '#BDBDBD'}
              d='m18.333 5.585-2.75-2.75V.917h2.75v4.668ZM22 11h-2.75v9.167H2.75V11H0L11 0l11 11Zm-9.167 1.833H9.167v5.5h3.666v-5.5Z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/payments')}
          className={`flex space-x-2 ${
            admin === '/admin/payments' ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray' : 'text-gray-400'
          }`}
        >
          <p>{t('admin.payments')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='23'
            fill='none'
            viewBox='0 0 25 23'
          >
            <path
              fill={admin === '/admin/payments' ? '#FF002B' : '#BDBDBD'}
              fillRule='evenodd'
              d='M17.894 0c3.193 0 5.783 2.554 5.783 5.704s-2.59 5.704-5.783 5.704c-3.194 0-5.783-2.553-5.783-5.704C12.111 2.554 14.7 0 17.894 0Zm.848 7.594h-.137v-1.35c.173.039.473.082.63.144.643.255.28 1.206-.493 1.206Zm-1.37-3.828h.32v1.351c-.173-.003-.554-.089-.697-.123-.825-.2-.47-1.228.377-1.228Zm.32-1.26v.315c-.246 0-.61.041-.807.105-1.607.522-1.57 2.132-.647 2.724.42.27.91.288 1.454.413v1.531c-1.297 0-1.03-.574-1.232-.947-.243-.446-.869-.109-.869.182 0 1.131 1.035 1.711 2.1 1.711 0 .178-.067.72.457.72.421 0 .457-.454.457-.765 2.123 0 2.587-2.566.658-3.036-.145-.035-.22-.058-.368-.087-.405-.081-.29.11-.29-1.606 1.337 0 .652 1.126 1.37 1.126.94 0 .371-1.3-.091-1.622-.643-.448-.7-.316-1.28-.45 0-1.005-.912-.64-.912-.315Zm.18 16.724c-1.2.32-3.865.178-5.25.178-2.545 0-2.798-.231-4.088 1.27l-7.607-.685c.394-1.466 1.621-4.267 2.363-5.471 1.896-3.078 4.826-2.459 9.332-2.459 1.347 0 2.732-.275 3.085.885.424 1.393-.718 1.788-1.854 1.784-2.238-.008-3.23-.325-3.094.695.679.237 4.375.136 5.419.114 2.95-.06 5.691-3.44 7.124-3.66.832-.127 1.486.356 1.649 1.017.314 1.276-.946 1.784-1.61 2.423-1.427 1.371-3.648 3.423-5.469 3.908Zm-8.834 3.711L0 22.921l.04-2.053 6.775.608c.377.035.755.072 1.132.103l.971.168c.022-.032.076.026.117.044l.003 1.15Z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/debt/request')}
          className={`flex space-x-2 ${
            admin === '/admin/debt/request'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('DebtRequest.debtRequest')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='23'
            fill='none'
            viewBox='0 0 25 23'
          >
            <path
              fill={admin === '/admin/debt/request' ? '#FF002B' : '#BDBDBD'}
              fillRule='evenodd'
              d='M17.894 0c3.193 0 5.783 2.554 5.783 5.704s-2.59 5.704-5.783 5.704c-3.194 0-5.783-2.553-5.783-5.704C12.111 2.554 14.7 0 17.894 0Zm.848 7.594h-.137v-1.35c.173.039.473.082.63.144.643.255.28 1.206-.493 1.206Zm-1.37-3.828h.32v1.351c-.173-.003-.554-.089-.697-.123-.825-.2-.47-1.228.377-1.228Zm.32-1.26v.315c-.246 0-.61.041-.807.105-1.607.522-1.57 2.132-.647 2.724.42.27.91.288 1.454.413v1.531c-1.297 0-1.03-.574-1.232-.947-.243-.446-.869-.109-.869.182 0 1.131 1.035 1.711 2.1 1.711 0 .178-.067.72.457.72.421 0 .457-.454.457-.765 2.123 0 2.587-2.566.658-3.036-.145-.035-.22-.058-.368-.087-.405-.081-.29.11-.29-1.606 1.337 0 .652 1.126 1.37 1.126.94 0 .371-1.3-.091-1.622-.643-.448-.7-.316-1.28-.45 0-1.005-.912-.64-.912-.315Zm.18 16.724c-1.2.32-3.865.178-5.25.178-2.545 0-2.798-.231-4.088 1.27l-7.607-.685c.394-1.466 1.621-4.267 2.363-5.471 1.896-3.078 4.826-2.459 9.332-2.459 1.347 0 2.732-.275 3.085.885.424 1.393-.718 1.788-1.854 1.784-2.238-.008-3.23-.325-3.094.695.679.237 4.375.136 5.419.114 2.95-.06 5.691-3.44 7.124-3.66.832-.127 1.486.356 1.649 1.017.314 1.276-.946 1.784-1.61 2.423-1.427 1.371-3.648 3.423-5.469 3.908Zm-8.834 3.711L0 22.921l.04-2.053 6.775.608c.377.035.755.072 1.132.103l.971.168c.022-.032.076.026.117.044l.003 1.15Z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/errors')}
          className={`flex space-x-2 ${
            admin === '/admin/errors'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('error.error1')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='21'
            fill='none'
            viewBox='0 0 22 21'
          >
            <path
              fill={admin === '/admin/errors' ? '#FF002B' : '#BDBDBD'}
              d='m18.333 5.585-2.75-2.75V.917h2.75v4.668ZM22 11h-2.75v9.167H2.75V11H0L11 0l11 11Zm-9.167 1.833H9.167v5.5h3.666v-5.5Z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/types')}
          className={`flex space-x-2 ${
            admin === '/admin/types'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('admin.types')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke={admin === '/admin/types' ? '#FF002B' : '#BDBDBD'}
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z'
            />
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 6h.008v.008H6V6z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/chars')}
          className={`flex space-x-2 ${
            admin === '/admin/chars'
              ? 'text-auctionicRed border-b-2 border-b-darkGray font-semibold'
              : 'text-gray-400'
          }`}
        >
          <p>{t('Characteristics.characteristics')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/interest-rate')}
          className={`flex space-x-2 ${
            admin === '/admin/interest-rate'
              ? 'text-auctionicRed border-b-2 border-b-darkGray font-semibold'
              : 'text-gray-400'
          }`}
        >
          <p>{t('InterestRate.interestRate')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/post/confirmation')}
          className={`flex space-x-2 ${
            admin === '/admin/post/confirmation'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('PostConfirmation.postConfirmation')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z'
            />
          </svg>
        </button>
      </li>
      <li className='flex justify-end'>
        <button
          onClick={() => navigate('/admin/agreements')}
          className={`flex space-x-2 ${
            admin === '/admin/agreements'
              ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray'
              : 'text-gray-400'
          }`}
        >
          <p>{t('Agreements.agreements')}</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002'
            />
          </svg>
        </button>
      </li>
      {user?.super_admin == true ? (
        <li className='flex justify-end'>
          <button
            onClick={() => navigate('/admin/list')}
            className={`flex space-x-2 ${
              admin === '/admin/list' ? 'text-auctionicRed font-semibold border-b-2 border-b-darkGray' : 'text-gray-400'
            }`}
          >
            <p>Admins</p>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              fill='none'
              viewBox='0 0 18 18'
            >
              <path
                fill={admin === '/admin/list' ? '#FF002B' : '#BDBDBD'}
                d='M14.25 5.25a5.25 5.25 0 1 1-10.501 0 5.25 5.25 0 0 1 10.501 0Zm-1.198 5.386A6.702 6.702 0 0 1 9 12a6.714 6.714 0 0 1-4.055-1.367C1.89 11.98 0 16.166 0 18h18c0-1.817-1.95-6.005-4.948-7.364Z'
              />
            </svg>
          </button>
        </li>
      ) : null}
    </ul>
  );
};

export { Sidenav };
