import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidenav } from '../Sidenav/Sidenav';
import { SearchIndex } from '../SearchIndex/SearchIndex';
import { UsersTraffic } from '../UserTraffic';

const Users = () => {
  const [users, setUsers] = useState('searchIndex');
  const handleChangeUsers = (event: any) => {
    setUsers(event);
  };
  const { t } = useTranslation();
  return (
    <div className='bg-white min-h-screen'>
      <div className='grid grid-cols-4'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px] '>
          <div className=''>
            <ul className='grid grid-cols-2 text-center font-semibold '>
              <li className='text-[20px] '>
                <button
                  className={`text-[20px] ${
                    users === 'searchIndex'
                      ? 'sm:border-b-2 sm:border-b-auctionicRed text-auctionicRed'
                      : ''
                  }`}
                  onClick={() => handleChangeUsers('searchIndex')}
                >
                  {t('admin.searchIndex')}
                </button>
              </li>
            </ul>
            <div className=''>
              {users === 'searchIndex' && <SearchIndex />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Users };
