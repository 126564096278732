import React from 'react';
import { adminServices } from '../../services/adminServices';
import { Sidenav } from '../Sidenav/Sidenav';
import { Accepted } from './Accepted';
import { useTranslation } from 'react-i18next';
import { Declined } from './Declined';
import { Discarded } from './Discarded';
import { Successful } from './Successful';
import { WaitingToAccept } from './WaitingToAccept';

const Agreements = () => {
  const { t } = useTranslation();
  const [agreementType, setAgreementType] = React.useState('waiting');
  const [viewPropertyData, setViewPropertyData] = React.useState<Array<string>>(
    []
  );
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  const handleGetInvoice = (property_id: number) => {
    const newTab = window.open();
    setIsButtonLoading(true);
    adminServices
      .getPropertyInvoice(property_id)
      .then((response: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newTab.location.href = response?.data?.link;
        setIsButtonLoading(false);
      })
      .catch((error: any) => {
        console.log('Error getting the chars', error);
        setIsButtonLoading(false);
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
  };

  return (
    <div className='bg-white min-h-screen'>
      <div className='grid grid-cols-1 sm:grid-cols-4 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <ul className='grid grid-cols-5 mx-5 gap-5'>
            <li>
              <button
                onClick={() => setAgreementType('waiting')}
                className={`text-[20px] font-semibold ${
                  agreementType === 'waiting'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('Agreements.WaitToAccept')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setAgreementType('accepted')}
                className={`text-[20px] font-semibold ${
                  agreementType === 'accepted'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('Agreements.accepted')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setAgreementType('success')}
                className={`text-[20px] font-semibold ${
                  agreementType === 'success'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('Agreements.successful')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setAgreementType('declined')}
                className={`text-[20px] font-semibold ${
                  agreementType === 'declined'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('Agreements.declined')}
              </button>
            </li>
            <li>
              <button
                onClick={() => setAgreementType('discarded')}
                className={`text-[20px] font-semibold ${
                  agreementType === 'discarded'
                    ? 'sm:border-b-4 sm:border-b-red-700'
                    : ''
                }`}
              >
                {t('Agreements.discarded')}
              </button>
            </li>
          </ul>
          <div className='grid grid-cols-5 '>
            <div className='col-span-full sm:col-span-4 overflow-x-auto mt-[36px]'>
              {agreementType === 'waiting' ? (
                <WaitingToAccept
                  viewPropertyData={viewPropertyData}
                  setViewPropertyData={setViewPropertyData}
                />
              ) : agreementType === 'accepted' ? (
                <Accepted
                  viewPropertyData={viewPropertyData}
                  setViewPropertyData={setViewPropertyData}
                  handleGetInvoice={handleGetInvoice}
                  isButtonLoading={isButtonLoading}
                />
              ) : agreementType === 'success' ? (
                <Successful
                  viewPropertyData={viewPropertyData}
                  setViewPropertyData={setViewPropertyData}
                  handleGetInvoice={handleGetInvoice}
                  isButtonLoading={isButtonLoading}
                />
              ) : agreementType === 'declined' ? (
                <Declined
                  viewPropertyData={viewPropertyData}
                  setViewPropertyData={setViewPropertyData}
                />
              ) : agreementType === 'discarded' ? (
                <Discarded
                  viewPropertyData={viewPropertyData}
                  setViewPropertyData={setViewPropertyData}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Agreements };
