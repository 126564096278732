import React, { useState } from 'react';
import { RevenueContent } from './RevenueContent';
import { AdRevenue } from './AdRevenue';
import { Comission } from './Comission';
import { useTranslation } from 'react-i18next';
import { Sidenav } from '../Sidenav/Sidenav';

const Revenue = () => {
  const [revenue, setRevenue] = useState('revenues');
  const handleChangeRevenue = (event: any) => {
    setRevenue(event);
  };
  const { t } = useTranslation();

  return (
    <div className='bg-white min-h-screen'>
      <div className='grid sm:grid-cols-4 grid-cols-1 pb-52'>
        <div className='col-span-1 flex justify-center pt-[96px]'>
          <Sidenav />
        </div>
        <div className='col-span-3 pt-[68px]'>
          <ul className='grid grid-cols-3 sm:px-10 pl-2  '>
            <li>
              <button
                className={`text-[20px] ${
                  revenue === 'revenues'
                    ? 'sm:border-b sm:border-b-red-700'
                    : ''
                }`}
                onClick={() => handleChangeRevenue('revenues')}
              >
                {t('admin.revenue')}
              </button>
            </li>
            <li>
              <button
                className={`text-[20px] ${
                  revenue === 'adRevenue'
                    ? 'sm:border-b sm:border-b-red-700'
                    : ''
                }`}
                onClick={() => handleChangeRevenue('adRevenue')}
              >
                {t('admin.adRevenue')}
              </button>
            </li>
            <li>
              <button
                className={`text-[20px] ${
                  revenue === 'comission'
                    ? 'sm:border-b sm:border-b-red-700'
                    : ''
                }`}
                onClick={() => handleChangeRevenue('comission')}
              >
                {t('admin.comission')}
              </button>
            </li>
          </ul>
          {revenue === 'revenues' && <RevenueContent />}
          {revenue === 'adRevenue' && <AdRevenue />}
          {revenue === 'comission' && <Comission />}
        </div>
      </div>
    </div>
  );
};

export { Revenue };
